import * as utils from '../utils';

export default function AggregatePivotTableModel() {
    return $.extend(this, {
        showSubtotalsCols: true,
        showSubtotalsRows: true
    });
}

AggregatePivotTableModel.prototype = {
    constructor: AggregatePivotTableModel,
    create: function() {
        this.bucketValues();
        this.table = this.createTable();
    },

    // init for Analysis page--does this belong here or somewhere else?
    init: function(pivotDiv, response) {
        var self = this;

        self.pivotDiv = pivotDiv;
        self.responseMeta = response.meta;
        self.dataset = self.responseMeta.dataset;
        if (!response.success) {
            return;
        }

        self.data = response.results.data;
        self.isGradeData = ['assessments', 'assessment_students', 'course_grades', 'objectives'].includes(self.dataset);
        self.isAssessmentStudentData = self.dataset == 'assessment_students',
        self.isDemeritData = (self.dataset == 'demerits');
        self.isAbsenceData = (self.dataset == 'absences');

        self.left = self.responseMeta.dimensions[0];
        self.left2 = self.responseMeta.dimensions[1];
        self.top = self.responseMeta.dimensions[2];
        self.top2 = self.responseMeta.dimensions[3];

        self.sortVal = _.get(self.responseMeta, 'pivot.sort_columns', pivotDiv.find('[name="sort_columns"]:checked').val());
        self.scoreDisplay = _.get(self.responseMeta, 'pivot.score_display', pivotDiv.find('[name="score_display"]:checked').val());
        self.behaviorValue = _.get(self.responseMeta, 'pivot.behavior_value', pivotDiv.find('[name="behavior_value"]:checked').val());
        self.func = _.get(self.responseMeta, 'pivot.func', pivotDiv.find('[name="func"]:checked').val());
        self.showGrowth = _.get(self.responseMeta, 'pivot.show_growth', pivotDiv.find('[name="show_growth"]:checked').val());

        var funcNames = {
            'avg': 'Avg',
            'sum': 'Sum',
            'count': 'Count',
            'pct': 'Pct',
            'pct_level_and_above': 'At or Above Goal',
            'pct_taken': '% Taken'
        };

        var growthKey = ! self.breakoutValid(self.top2) && self.breakoutValid(self.top) ? self.top.id : '';

        self.sortColumnsDesc = (self.sortVal == 'desc');
        self.showLevel = (self.scoreDisplay == 'level');
        self.canShowGrowth = (growthKey == 'assessment_id'
                || growthKey == 'termbin.term_bin_name'
                || growthKey.substr(0, 5) == 'date.');

        self.funcText = funcNames[self.func];
        self.showGrowth = (self.canShowGrowth && self.showGrowth == 1);
        self.gradingScale = (self.responseMeta.parameters) ? self.responseMeta.parameters['grading_scale'] : null;
        self.isPct = ((self.isGradeData && self.gradingScale && self.gradingScale.is_pct == 1)
            || self.func == 'pct'
            || (self.func == 'avg' && self.isAbsenceData));

        //set grade color scale function
        if (self.isGradeData && self.gradingScale) {
            var levels = self.gradingScale.levels;

            // reverse since we build up the bars from F to A.
            self.colorScale = Functions.getBackgroundColorScale(levels, true);
        }

        self.decimals = self.behaviorValue.match(/detention_value/) ? 3 : 0;

        //create the pivot table
        self.create();
    },

    bucketValues: function() {
        var self = this,
            temp = null;

        var leftSwapped = false;
        if (!self.breakoutValid(self.left) && self.breakoutValid(self.left2)) {
            temp = self.left;
            self.left = self.left2;
            self.left2 = temp;
            leftSwapped = true;
        }
        var topSwapped = false;
        if (!self.breakoutValid(self.top) && self.breakoutValid(self.top2)) {
            temp = self.top;
            self.top = self.top2;
            self.top2 = temp;
            topSwapped = true;
        }
        self.pivotTableWrapper = self.pivotDiv.find('.pivotTableWrapper');
        self.totalsKey = {id: '_totals', name: 'Totals'};
        self.totals = {};
        self.allTopKeys = {};

        var totalInitVal = {t: 0, c: 0};
        if (self.isGradeData) {
            totalInitVal.cLevelAndAbove = 0;
            if (self.isAssessmentStudentData) {
                totalInitVal.cTaken = 0;
            }
        }

        $.each(self.top.values, function(j, tv1) {

            $.each(self.top2.values, function(k, tv2) {

                $.each(self.left.values, function(i, lv1) {

                    $.each(self.left2.values, function(m, lv2) {
                        var x1 = (! leftSwapped) ? lv1.id : lv2.id;
                        var x2 = (! leftSwapped) ? lv2.id : lv1.id;
                        var y1 = (! topSwapped) ? tv1.id : tv2.id;
                        var y2 = (! topSwapped) ? tv2.id : tv1.id;
                        var cell = Functions.get(self.data, [x1, x2, y1, y2]);

                        if (!cell) {
                            return;
                        }

                        self.allTopKeys[tv1.id] = self.allTopKeys[tv1.id] || {};
                        self.allTopKeys[tv1.id][tv2.id] = self.allTopKeys[tv1.id][tv2.id] || {};

                        var gradingScale = self.getGradingScaleFromDataCache(cell.grading_scale_id);
                        var val = $.extend({}, totalInitVal, {c: cell.rowcount, grading_scale: gradingScale});
                        if (self.isGradeData) {
                            val.t = cell.score;
                            val.cLevelAndAbove = cell.pivotlevelandabove || 0;
                            if (self.isAssessmentStudentData) {
                                val.cTaken = cell.rowcount - cell.missing;
                            }
                        } else if (self.isDemeritData) {
                            if (self.behaviorValue == 'amount') {
                                val.t = cell.amount;
                            } else if (self.behaviorValue == 'cash_value') {
                                val.t = cell.cash;
                            } else if (self.behaviorValue == 'demerit_value') {
                                val.t = cell.demerits;
                            } else if (self.behaviorValue == 'detention_value') {
                                val.t = cell.detentions;
                            } else if (self.behaviorValue == 'lunch_detention_value') {
                                val.t = cell.lunch_detentions;
                            } else if (self.behaviorValue == 'third_detention_value') {
                                val.t = cell.third_detentions;
                            }
                        } else if (self.isAbsenceData && self.func == 'avg') {
                            val.t = cell.pct * cell.den_all_dims;
                            val.c = cell.den_all_dims;
                        } else {
                            val.t = cell.rowcount;
                        }

                        // set value for cell
                        Functions.set(self.totals, val, [lv1.id, lv2.id, tv1.id, tv2.id]);

                        // calculate various subtotals
                        var subtotals = [];

                        // per top and top2 combination subtotals
                        subtotals[subtotals.length] = {
                            key: [self.totalsKey.id, self.totalsKey.id, tv1.id, tv2.id],
                        };

                        // per left, top and top2 combination subtotals
                        subtotals[subtotals.length] = {
                            key: [lv1.id, self.totalsKey.id, tv1.id, tv2.id],
                        };

                        // per left and left2 combination subtotals
                        subtotals[subtotals.length] = {
                            key: [lv1.id, lv2.id, self.totalsKey.id, self.totalsKey.id],
                        };

                        // per left, left2 and top combination subtotals
                        subtotals[subtotals.length] = {
                            key: [lv1.id, lv2.id, tv1.id, self.totalsKey.id],
                        };

                        // per left, top combination subtotals
                        subtotals[subtotals.length] = {
                            key: [lv1.id, self.totalsKey.id, tv1.id, self.totalsKey.id],
                        };

                        // per left combination subtotals
                        subtotals[subtotals.length] = {
                            key: [lv1.id, self.totalsKey.id, self.totalsKey.id, self.totalsKey.id],
                        };

                        // per top value subtotal
                        subtotals[subtotals.length] = {
                            key: [self.totalsKey.id, self.totalsKey.id, tv1.id, self.totalsKey.id],
                        };

                        // totals
                        subtotals[subtotals.length] = {
                            key: [self.totalsKey.id, self.totalsKey.id, self.totalsKey.id, self.totalsKey.id],
                        };

                        subtotals.forEach(function(subtotal) {
                            var totalVal = Functions.get(self.totals, subtotal.key) || $.extend({}, totalInitVal, { grading_scale: gradingScale });

                            if (self.isGradeData && totalVal.grading_scale.is_pct !== gradingScale.is_pct) {
                                // if old is pct, ignore new
                                // if old is not pct, replace with new
                                if (totalVal.grading_scale.is_pct != 1) {
                                    totalVal.t = val.t;
                                    totalVal.c = 1;
                                    totalVal.cLevelAndAbove = val.cLevelAndAbove;
                                    totalVal.cTaken = val.cTaken;
                                    totalVal.grading_scale = gradingScale;
                                }
                            } else {
                                totalVal.t += val.t;
                                totalVal.c += val.c;
                                if (self.isGradeData) {
                                    totalVal.cLevelAndAbove += val.cLevelAndAbove;
                                    if (self.isAssessmentStudentData) {
                                        totalVal.cTaken += val.cTaken;
                                    }
                                }
                            }

                            Functions.set(self.totals, totalVal, subtotal.key);
                        });
                    });
                });
            });
        });

        // Note: got rid of the left2/top2 lines because they were screwing up genuine null/""/'None' rows/columns
        // Left the uncommented rows so we don't see 'All' when there are no breakouts selected
        if (!self.breakoutValid(self.left)) delete self.totals['']; // 'All' row
        if (!self.breakoutValid(self.top)) delete self.allTopKeys['']; // 'All' column
    },

    createTable: function() {
        var self = this,
            rows = {headers: '', rows: ''},
            sortedLeftKeys = self.getSortedVals(self.left.values, self.totals),
            sortedTopKeys = self.getSortedVals(self.top.values, self.allTopKeys, true, self.sortColumnsDesc),
            headerStr = '',
            headerStr2 = '',
            subHeaderStr = '',
            maxLen = 68,
            rag = null;

        if (self.func in AnalysisView.dashboardColumns) {
            rag = JSON.parse(AnalysisView.dashboardColumns[self.func] || '{}').rag;
        }

        if (!self.breakoutValid(self.top2)) {
            self.showSubtotalsCols = false; // can't show subtotal cols if there's no sub-headers
        }

        if (!self.breakoutValid(self.left2)) {
            self.showSubtotalsRows = false; // can't show subtotal rows if there's no row headers
        }

        $.each(sortedTopKeys, function(i, topVal) {
            if (topVal.id === self.totalsKey.id) return true; // continue

            var displayName = topVal.name,
                tooLong = displayName.length > maxLen,
                name = tooLong ? displayName.substr(0, maxLen) + '...' : displayName,
                title = tooLong ? displayName : '',
                sortedTop2Keys = self.getSortedVals(self.top2.values, self.allTopKeys[topVal.id], true, self.sortColumnsDesc),
                subCols = sortedTop2Keys.length - (!self.showSubtotalsCols || !self.breakoutValid(self.top2) ? 1 : 0),
                cols = (self.showGrowth && i > 0 ? 2 : 1) * subCols,
                colspan = cols > 1 ? ' colspan="' + cols + '"' : '',
                clazz = colspan || self.showGrowth ? ' class="section-start section-end"' : '';

            headerStr += '<th' + colspan + clazz + ' title="' + title + '">' + name + '</th>';

            $.each(sortedTop2Keys, function(k, top2Val) {
                if (!self.showSubtotalsCols && top2Val.id === self.totalsKey.id) return true; // continue

                var displayName = top2Val.name,
                    tooLong = displayName.length > maxLen,
                    name = tooLong ? displayName.substr(0, maxLen) + '...' : displayName,
                    title = tooLong ? displayName : '',
                    cols = (self.showGrowth && i > 0 ? 2 : 1),
                    colspan = cols > 1 ? ' colspan="' + cols + '"' : '',
                    clazz = k === 0 ? ' class="section-start"' : (k === sortedTop2Keys.length - 1 ? ' class="section-start section-end total"' : '');

                if (self.breakoutValid(self.top2)) {
                    headerStr2 += '<th' + colspan + clazz + ' title="' + title + '">' + name + '</th>';
                }

                if (self.showGrowth) {
                    subHeaderStr += (i > 0
                        ? '<th class="section-start">Growth</th><th class="section-end">Value</th>'
                        : '<th class="section-start section-end">Value</th>');
                }
            });
        });

        sortedLeftKeys.splice(0, 0, self.totalsKey);

        $.each(sortedLeftKeys, function(i, leftVal) {
            var leftTotal = self.totals[leftVal.id],
                isTotalsRow = (leftVal.id === self.totalsKey.id),
                key = (isTotalsRow ? 'headers' : 'rows'),
                sortedLeft2Keys = self.getSortedVals(self.left2.values, self.totals[leftVal.id], true);

            if (isTotalsRow) {
                rows[key] += '<tr class="totals-row"><td class="text">' + self.funcText + '</td>';
                sortedLeft2Keys = [self.totalsKey];
            } else {
                var displayName = leftVal.name,
                    tooLong = displayName.length > maxLen,
                    name = tooLong ? displayName.substr(0, maxLen) + '...' : displayName,
                    title = tooLong ? displayName : '',
                    numRows = sortedLeft2Keys.length - (self.showSubtotalsRows ? 0 : 1);

                rows[key] += (self.breakoutValid(self.left2) ? '<tbody>' : '') + '<tr><td class="text" data-val="' + leftVal.id + '" title="' + title + '"'
                        + (self.breakoutValid(self.left2) ? (' rowspan="' + numRows + '"') : '') + '>'
                    + utils.valueToLink(self.left.id, leftVal.id, name)
                    + '</td>';
            }

            $.each(sortedLeft2Keys, function(k, left2Val) {
                var isSubtotalsRow = (leftVal.id === self.totalsKey.id ^ left2Val.id === self.totalsKey.id);

                if (!self.showSubtotalsRows && isSubtotalsRow) return true; //continue;

                var left2Total = ((leftTotal && left2Val.id in leftTotal) ? leftTotal[left2Val.id] : null),
                    lastVal;

                if (self.breakoutValid(self.left2)) {
                    if (k !== 0) {
                        rows[key] += '<tr' + (isSubtotalsRow ? ' class="totals-row"' : '') + '>';
                    }

                    if (isTotalsRow) {
                        rows[key] += '<td class="text"></td>';
                    } else {
                        var displayName = left2Val.name,
                            tooLong = displayName.length > maxLen,
                            name = tooLong ? displayName.substr(0, maxLen) + '...' : displayName,
                            title = tooLong ? displayName : '';

                        rows[key] += '<td class="text" data-val="' + left2Val.id + '" title="' + title + '">'
                            + utils.valueToLink(self.left2.id, left2Val.id, name)
                            + '</td>';
                    }
                }

                $.each(sortedTopKeys, function(j, topVal) {
                    var topTotal = ((left2Total && topVal.id in left2Total) ? left2Total[topVal.id] : null),
                        isTotalsCol = (topVal.id === self.totalsKey.id),
                        sortedTop2Keys = isTotalsCol ? [self.totalsKey] : self.getSortedVals(self.top2.values, self.allTopKeys[topVal.id], true, self.sortColumnsDesc);

                    $.each(sortedTop2Keys, function(m, top2Val) {
                        var isSubtotalsColumn = (topVal.id === self.totalsKey.id ^ top2Val.id === self.totalsKey.id);

                        if ((!self.showSubtotalsCols || ! self.breakoutValid(self.top2)) && isSubtotalsColumn) return true; //continue;

                        var total = ((topTotal && top2Val.id in topTotal) ? topTotal[top2Val.id] : null),
                            isPctToUse = (total && total.grading_scale && total.grading_scale.is_pct == 1) || self.isAbsenceData,
                            isTotalsColumn = (topVal.id === self.totalsKey.id),
                            rowCount = Functions.get(self.totals, [leftVal.id, left2Val.id, self.totalsKey.id, self.totalsKey.id, 'c']),
                            val = (total ? (self.func == 'avg' ? Math.round(total.t / (total.c || 1) * (isPctToUse ? 1 : 10)) / (isPctToUse ? 1 : 10) + (isPctToUse ? '%' : '')
                                         : (self.func == 'pct' ? Math.round(100 * total.c / (rowCount || 1)) + '%'
                                         : (self.func == 'pct_level_and_above' ? Math.round(100 * total.cLevelAndAbove / (total.c || 1)) + '%'
                                         : (self.func == 'pct_taken' ? Math.round(100 * total.cTaken / (total.c || 1)) + '%'
                                         : (self.func == 'sum' ? Tss.Number.toCommas(total.t, self.decimals)
                                         : Tss.Number.toCommas(total.c)))))) : ''),
                            displayVal = val,
                            css = '',
                            cssClasses = (isTotalsColumn || isSubtotalsColumn ? 'avgCol' : '');

                        if (self.func === 'pct') {
                            css += ' title="' + (total ? (total.c + " / " + rowCount) : '') + '"';
                        } else if (self.isGradeData && self.func == 'avg' && val) {
                            var color = 'inherit';
                            var score = parseFloat(val.replace('%', ''));
                            var gradingScale = _.get(total, 'grading_scale');
                            var levels = gradingScale.levels;
                            var numLevels = levels.length;
                            var level = scoreToGradeLevel(score, gradingScale);

                            if (self.showLevel) {
                                displayVal = _.get(level, 'name');
                                title = val;
                            } else {
                                title = _.get(level, 'name');
                            }

                            var colorScale = Functions.getBackgroundColorScale(levels, true);

                            color = colorScale(numLevels - 1 - level.index)
                            css += ' title="' + title + '" style="background-color: ' + color + ';"';
                        } else if (rag) {
                            var c = addConditionalFormatting($(), myParseFloat(val), rag);

                            if (c) {
                                cssClasses += ' ' + c;
                            }
                        }

                        if (self.showGrowth && j > 0 && !isTotalsColumn) {
                            var digits = Math.max(numDigits(val), numDigits(lastVal));

                            rows[key] += '<td>' + (val && lastVal ?
                                Tss.Number.toCommas(myParseFloat(val) - myParseFloat(lastVal), digits)
                                    + (lastVal.indexOf('%') >= 0 ? '%' : '') :
                                '') + '</td>';
                        }

                        rows[key] += '<td' + css + ' class="' + cssClasses + '">' + displayVal + '</td>';
                        lastVal = val || lastVal;
                    });
                });

                rows[key] += '</tr>';
            });

            rows[key] += (self.breakoutValid(self.left2) ? '</tbody>' : '');
        });

        var blankHeaderColumns = '<th></th>'
                    + (self.breakoutValid(self.left) && self.breakoutValid(self.left2) ? '<th></th>' : ''),
            table = $('<table class="tablesorter demerit_table dashboard autoTotal pivot-table">'
                + '<thead>'
                + '<tr' + (subHeaderStr ? '' : ' class="headerRow"') + '>' + blankHeaderColumns
                    + headerStr + '<th class="avgCol">' + self.funcText + ' (' + (sortedTopKeys.length - 1) + ')</th></tr>'
                + (headerStr2 ? ('<tr' + (subHeaderStr ? '' : ' class="headerRow"') + '>' + blankHeaderColumns
                    + headerStr2 + '<th class="avgCol"></th></tr>') : '')
                + (subHeaderStr ? '<tr class="headerRow">' + blankHeaderColumns
                    + subHeaderStr + '<th class="avgCol">Value</th></tr>' : '')
                + rows.headers
                + '</thead>'
                + '<tbody>'
                + rows.rows
                + '</tbody>'
                + '</table>');

        self.pivotDiv.find('.showGrowth')[self.canShowGrowth ? 'show' : 'hide']();
        self.pivotDiv.find('.scoreDisplay')[self.isGradeData ? 'show' : 'hide']();
        self.pivotDiv.find('[for="func_pct_level_and_above"]')[self.isGradeData ? 'show' : 'hide']();
        self.pivotDiv.find('[for="func_pct_taken"]')[self.isAssessmentStudentData ? 'show' : 'hide']();
        self.pivotTableWrapper.empty().append(table);
        calculateTotals.apply(table);
        table.tablesorter();
        addHover();
        return table;
    },
    getSortedVals: function(values, totals, withTotals, reverse) {
        var self = this,
            //filter totals key and any key which is not in totals array
            vals = values.filter(function(v) {return v && totals && v.id !== self.totalsKey.id && v.id in totals;});

        if (reverse) {
            vals.reverse();
        }

        if (withTotals) {
            vals.push(self.totalsKey);
        }

        return vals;
    },
    breakoutValid: function(breakout) {
        return breakout.id !== '';
    },
    download: function(e, studentLabel) {
        var self = this;
        if (!self.table) {
            return false;
        }
        var $tableClone = Functions.handleTableSpansForExport(self.table),
            dataArray = [],
            isStudentLeft = (self.left.id == 'student_id');

        dataArray = dataArray.concat(rowsToValues($('tr', $tableClone), function(row, values) {

            if (isStudentLeft) {
                var studentId = row.find('td:first').data('val');
                var schoolIdCode = '';
                var sisId = '';
                var stateId = '';

                $.each(self.left.values, function(index, value) {

                    if (value.id == studentId) {
                        schoolIdCode = value.school_id_code;
                        sisId = value.sis_id;
                        stateId = value.state_id;
                        return false;
                    }
                });
                values.splice(1, 0, studentId, schoolIdCode, sisId, stateId);
            }

            return values;
        }));

        if (isStudentLeft) {
            var dataRowLength = dataArray[dataArray.length - 1].length;

            // populate empty cell
            if(dataRowLength === dataArray[0].length) {
                dataArray[0][1] = 'Student ID';
                dataArray[0][2] = 'SR Student Number';
                dataArray[0][3] = 'SIS Student Number';
                dataArray[0][4] = 'State ID';
            }
            //insert new cells
            else {
                dataArray[0].splice(1, 0, 'Student ID', 'SR Student Number', 'SIS Student Number', 'State ID');
            }
        }

        return genericExport(e, 'Analysis Pivot Table', dataArray);
    },
    getGradingScaleFromDataCache: function (gradingScaleId) {
        return _.get(AnalysisView.dataCache, 'grading_scales_by_id['+ gradingScaleId +']');
    }
};
