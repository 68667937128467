import * as models from './models';

var pivotTableModel;

export function fetchPivotDataForReport(reportId) {
    return $.get('/api/v1/analysis-reports/' + reportId + '/pivot-data');
}

// the element passd into here must have a child element with class ".pivotTableWrapper"
// <div id="{element_id}">
//     <div class="pivotTableWrapper"></div>
// </div> 
export function createTableFromResults(element, response) {
    pivotTableModel = new models.AggregatePivotTableModel();
    pivotTableModel.init(element, response);
}

export function loadInitialPivotData(event) {
    var tab       = $(this);
    var tabName   = tab.data('tab');
    var tabPane   = $(`#${tabName}`);
    var dataset = $('[name="dataset"]').val();

    // only do if we have an empty pivot table and dataset selected
    if (!tabPane.find('.pivotTableWrapper:empty').length || !dataset) {
        return;
    }

    // show loading indicator, util table exists in dom
    AnalysisView.showLoadingIndicatorUntil('Loading Pivot Table Data', () => !!tabPane.find('table').length);

    // trigger load of data
    tabPane.find('[name*="pivot_table"]').first().change();
}

export function getPivotDiv() {
    return $('#pivot > div');
}

export function getPivotTableLeftOption(pivotDiv) {
    return getSelectedOption(pivotDiv.find('[name="pivot_table_left"]'));
}

export function getPivotTableLeft2Option(pivotDiv) {
    return getSelectedOption(pivotDiv.find('[name="pivot_table_left2"]'));
}

export function getPivotTableTopOption(pivotDiv) {
    return getSelectedOption(pivotDiv.find('[name="pivot_table_top"]'));
}

export function getPivotTableTop2Option(pivotDiv) {
    return getSelectedOption(pivotDiv.find('[name="pivot_table_top2"]'));
}

export function pivotTableChange(e, preventRewrite) {
    var dataPicker = $('.data-picker'),
        pivotDiv = getPivotDiv(),
        pivotTableWrapper = pivotDiv.find('.pivotTableWrapper'),
        isPivotControl = ($(e.target).parents('.pivotControl').length > 0);

    if (preventRewrite || AnalysisView.suspendRedraw) {
        pivotTableWrapper.empty();
    } else if (isPivotControl) {
        handlePivotTableResults(dataPicker);
    } else {
        var requestData = AnalysisView.getRequestDataFromForm(dataPicker, 'pivot');

        if (!requestData.dataset) {
            return;
        }

        var topOption = getPivotTableTopOption(pivotDiv),
            top2Option = getPivotTableTop2Option(pivotDiv),
            leftOption = getPivotTableLeftOption(pivotDiv),
            left2Option = getPivotTableLeft2Option(pivotDiv),
            hasPivotLeft = (leftOption.val() !== ""),
            hasPivotLeft2 = (left2Option.val() !== ""),
            hasPivotTop = (topOption.val() !== ""),
            hasPivotTop2 = (top2Option.val() !== "");

        left2Option.closest('select').parent()[hasPivotLeft ? 'show' : 'hide']();
        top2Option.closest('select').parent()[hasPivotTop ? 'show' : 'hide']();

        if (!hasPivotLeft && hasPivotLeft2) {
            leftOption.closest('select').val(left2Option.val()).trigger('change', true);
            left2Option.closest('select').val('').change();
        }

        if (!hasPivotTop && hasPivotTop2) {
            topOption.closest('select').val(top2Option.val()).trigger('change', true);
            top2Option.closest('select').val('').change();
        }

        $.post('/api/v1/analysis/' + requestData.dataset + '/pivot-data', requestData, function(data) {
            dataPicker.data('pivotResults', data);
            handlePivotTableResults(dataPicker);
        });
    }

    return stopPropagation(e);
}

export function handlePivotTableResults(dataPicker) {
    var results = dataPicker.data('pivotResults'),
        pivotDiv = getPivotDiv(),
        pivotTableWrapper = pivotDiv.find('.pivotTableWrapper');

    if (!results || !results.success) {
        pivotTableWrapper.empty();
        return;
    }

    createTableFromResults(pivotDiv, results);
}

export function getPivotOptions() {
    return ['sort_columns', 'score_display', 'show_growth', 'behavior_value', 'raw_or_curved', 'mastery_results', 'func'];
}

export function getPivotTableModel() {
    return pivotTableModel;
}
