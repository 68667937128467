import ReportsSidebar from './reports/sidebar';
import ReportsVisualization from './reports/visualization';
import * as utils from './utils';

$(document).ready(function () {
    if (!$('.report-viewer').length) return;

    ReportsVisualization();
    ReportsSidebar();
    init(); //last so that bindings can be setup
});

function init() {
    $(window)
        .on('popstate', loadReportBasedOnBrowserUrl)
        .trigger('popstate');

    $('.report-viewer')
        .on('load-report', updateBrowserHistory);
}

function loadReportBasedOnBrowserUrl() {
    var reportId = utils.getReportIdFromUrl();
    $('.report-viewer').trigger('load-report', reportId);
}

function updateBrowserHistory(event, analysisReportId) {
    $('.report-viewer').data('identifier', `Report ${analysisReportId}`)
    return utils.updateBrowserHistory(analysisReportId);
}
