import * as utils from '../utils';
import AggregateGradeModel from './grade';

export default function AggregateObjectiveModel() {
    return AggregateGradeModel.apply(this); // super();
}

// inheritance! give sub-class the prototype members of the super class and then override with the child methods
utils.inheritPrototype(AggregateGradeModel, AggregateObjectiveModel, {
    //override when all/last mastery results is not part of graph
    getValueKeys: function() {
        var self = this;

        return {
            label: 'Score',
            key: 'value',
            avgKey: 'avgScore',
            countKey: 'count',
            totalCountKey: 'totalCount',
            totalAvgKey: 'totalAvgScore',
            colorKey: 'avgColor',
            recordsKey: 'records'
        };
    }
    /*
    // overrides required when all/last mastery results control is part of graph
    getValueKeys: function() {
        var self = this;

        var controls = {};
        var valueKeys = null;
        var useLast = (self.responseMeta.parameters['mastery_results'] === 'last');
        self.augmentControls(controls);
        $.each(controls.dataControls, function(index, dataControl) {
            if (dataControl.key === ((useLast) ? 'lastValue' : 'value')) {
                valueKeys = dataControl;
            }
        });
        return valueKeys;
    },
    augmentControls: function(controls) {
        AggregateObjectiveModel.prototype.parent.augmentControls.call(this, controls);

        controls.dataControls = [
            {
                label: 'All',
                key: 'value',
                avgKey: 'avgScore',
                countKey: 'count',
                totalCountKey: 'totalCount',
                totalAvgKey: 'totalAvgScore',
                colorKey: 'avgColor',
                title: 'All Assessment Results',
                recordsKey: 'records'
            },
            {
                label: 'Last',
                key: 'lastValue',
                avgKey: 'lastAvgScore',
                countKey: 'lastCount',
                totalCountKey: 'totalLastCount',
                totalAvgKey: 'totalAvgScore',
                colorKey: 'lastColor',
                title: 'Most Recent Assessment Only',
                recordsKey: 'lastRecords'
            },
        ];
    }
    */,
   createRawTableRow: function(dataRow) {
        var self = this;

        return [
                '',
                utils.valueToLink('student_id', dataRow.student_id, dataRow.student_name),
                dataRow.student_id,
                dataRow.school_id_code,
                dataRow.sis_id,
                dataRow.state_id,
                dataRow.homeroom || '',
                utils.valueToLink('assessment_id', dataRow.assessment_id, dataRow.assessment_name),
                utils.valueToLink('objective_id', dataRow.objective_id, dataRow.objective_name),
                dateStringToDisplay(dataRow.date),
                utils.valueToLink('course_id', dataRow.course_id, dataRow.course_name || ''),
                utils.valueToLink('staff_member_id', dataRow.staff_member_id, dataRow.staff_member_name || ''),
                self.gradingScale.is_pct == 0 ? dataRow.score.toFixed(4) : (dataRow.score.toFixed() + '%'),
                dataRow.department_name || '',
                dataRow.term_bin_name
            ];
    },
    createRawTableTotalRow: function(response) {
        var self = this,
            totals = [];

        totals.length = 14;
        if (response.success) {
            var summary = response.meta.summary,
                totalRows = summary.total_rows,
                i = 0;
            totals[i++] = self.createRawTableUniqueCell(summary.student_uniq, totalRows);
            totals[i++] = self.createRawTableUniqueCell(summary.student_uniq, totalRows);
            totals[i++] = self.createRawTableUniqueCell(summary.school_id_code_uniq, totalRows);
            totals[i++] = self.createRawTableUniqueCell(summary.sis_uniq, totalRows);
            totals[i++] = self.createRawTableUniqueCell(summary.state_id_uniq, totalRows);
            totals[i++] = self.createRawTableUniqueCell(summary.homeroom_uniq, totalRows);
            totals[i++] = self.createRawTableUniqueCell(summary.assessment_uniq, totalRows);
            totals[i++] = self.createRawTableUniqueCell(summary.objective_uniq, totalRows);
            i++; //skip date
            totals[i++] = self.createRawTableUniqueCell(summary.course_uniq, totalRows);
            totals[i++] = self.createRawTableUniqueCell(summary.staff_member_uniq, totalRows);
            totals[i++] = self.createRawTableAvgScoreCell(summary.score_sum, totalRows);
            totals[i++] = self.createRawTableUniqueCell(summary.department_uniq, totalRows);
            totals[i++] = self.createRawTableUniqueCell(summary.term_bin_uniq, totalRows);
        }

        return totals;
    }
});