import * as utils from './utils';

var rawDataHeaders;

export function setHeaders(headers) {
    rawDataHeaders = headers;
}

export function getHeaders() {
    return rawDataHeaders;
}

export function showAllData(event, triggeredViaChartClick = false) {
    var tab       = $(this);
    var tabName   = tab.data('tab');
    var tabPane   = $(`#${tabName}`);
    var dataModel = $('#chartWrapper').data('model');

    // we have a dataset,
    // and there currently isnt a table or we have triggered via chart click (new dimension values).
    if (dataModel.dataset && (triggeredViaChartClick || tabPane.find('#tableWrapper:empty').length)) {
        // show loading indicator, until we have a number of pages
        AnalysisView.showLoadingIndicatorUntil('Loading Raw Data', () => !!$('.tablesorter-pager .page-number input').val());

        // create or update datatable
        setTimeout(() => createOrUpdateDataTable(dataModel), 500);
    }
}

// update raw table for this dataset
export function createOrUpdateDataTable(model) {
    // we must have a model and not preventing redraw to continue 
    if (!model) {
        return;
    }

    // get headers and get table element or create then return it
    var headers = getHeaders();
    var table = getOrAppendTableHtml(model, headers);

    // build request data
    var requestData = buildRequestDataForModel(model);

    var clearBtn = $('#tableWrapper .clear');
    if (model.rawDataSelDimValues.length) {
        clearBtn.show();
    } else {
        clearBtn.hide();
    }

    var requestType = model.getRecordsRequestBaseUri().includes('analysis-reports') ? 'GET' : 'POST';

    // init table sorter and tablesorter pager
    table.tablesorter().tablesorterPager({
        container: $('[name="pager_' + table.attr('name') + '"]'),
        positionFixed: false,
        output: '{totalPages}',
        cssGoto: '.gotoPage',
        cssHeaderRow: 'headerRow',
        ajaxUrl: model.getRecordsRequestBaseUri() + '?page={page}&rowsperpage={size}&{sortList:sortcol}',
        customAjaxUrl: function(table, url) {
            var query = url.substring(url.indexOf('?') + 1),
                queryParts = query.split("&"),
                params = [],
                sorts = [];
            $.each(queryParts, function(i, queryPart) {
                var sortmatch = /^sortcol\[(\d+)\]=(\d)$/.exec(queryPart);
                if (sortmatch) {
                    if (sortmatch[1] >= 0 && sortmatch[1] < headers.length && headers[sortmatch[1]].sortkey) {
                        sorts.push(headers[sortmatch[1]].sortkey + '=' + sortmatch[2]);
                    }
                } else {
                    var paramParts = queryPart.split('=');
                    params[paramParts[0]] = paramParts[1];
                }
            });
            table.config.pager.ajaxObject.data.page = params['page'];
            table.config.pager.ajaxObject.data.rowsperpage = params['rowsperpage'];
            if (sorts.length) {
                table.config.pager.ajaxObject.data.sort = sorts.join('&');
                requestData.sort = sorts.join('&'); //add to request data for use in download link
            }
            return url.substring(0, url.indexOf('?'));
        },
        ajaxObject: {
            type: requestType,
            data: requestData,
            dataType: 'json',
            beforeSend: function () {
                table.addClass('loading');
            },
            success: function () {
                table.removeClass('loading');
            }
        },
        ajaxProcessing: function(response) {
            return model.createRawTableRows(table, response);
        },
        page: 0,
        size: 10,
        removeRows: true,
        savePages: false
    });

    $('#tableWrapper')
        .off('click', '.clear')
        .on('click', '.clear', function(e) {
            $('body').trigger('clearRawTable');
            createOrUpdateDataTable(model);
        });

    $('#tableWrapper')
        .off('click', '.download')
        .on('click', '.download', function(e) {
            $.fileDownload('/api/v1/analysis/' + model.responseMeta.dataset + '/download', {
                preparingMessageHtml: "We are preparing your report, please wait...",
                failMessageHtml: "There was a problem generating your report, please try again.",
                httpMethod: "POST",
                data: requestData
            });
        });
}

function getOrAppendTableHtml(model, headers) {
    var table = $('#tableWrapper table[name="' + model.dataset + '"]');
    var containerWidth = $('#tableWrapper').width();

    if (!table.length) {
        $('#tableWrapper')
            .append($('<div class="ui-corner-all"/>')
            .append('<div name="pager_' + model.dataset + '" class="tablesorterPager">'
                + '<i class="icon-step-backward page-btn first"></i>'
                + '<i class="icon-backward page-btn prev"></i>'
                + '<i class="icon-forward page-btn next"></i>'
                + '<i class="icon-step-forward page-btn last"></i>'
                + '<span class="page-number">Page <input type="number" class="gotoPage small tiny"> of <span class="pagedisplay"></span></span>'
                + '<select class="pagesize">'
                    + '<option selected="selected" value="10">10</option>'
                    + '<option value="25">25</option>'
                    + '<option value="50">50</option>'
                    + '<option value="100">100</option>'
                + '</select>'
                + '<span>Results per page</span>'
                + '<span class="raw-data-actions right">'
                    + '<a href="javascript:void(0);" class="clear hide" title="Clear Filters">'
                        + '<i class="icon-remove"></i> Clear Filters'
                    + '</a> '
                    + '<a href="javascript:void(0);" class="download" title="Download">'
                        + '<i class="icon-download"></i> Download'
                    + '</a>'
                + '</span>'
                + '</div>')
            .append(table = $('<table class="tablesorter demerit_table dashboard hasPager autoTotal" name="' + model.dataset + '">'
                + '<colgroup></colgroup>'
                + '<thead>'
                + model.createRawTableHeader(headers)
                + '</thead>'
                + '<tbody></tbody>'
                + '</table>')));

        // wrap the table sorter in wrapper, then set its width to make it scroll horizontally
        $('#tableWrapper .tablesorter').wrap('<div class="tablesorter-wrapper"></div/>');
        $('.tablesorter-wrapper').css('width', containerWidth);
    } else {
        $.tablesorter.clearTableBody(table);
        model.resetRawTableTotals(table);
        table.trigger('pageSet', 0);
    }

    return table;
}

function buildRequestDataForModel(model) {
    var requestData = {};

    $.each(model.responseMeta.dimensions, function(i, dimension) {
        requestData['dimension_' + (i+1)] = dimension.id;
    });

    requestData['dimensionvalues'] = model.rawDataSelDimValues;

    // add filters from chat response
    requestData = _.extend({}, requestData, model.responseMeta.filters);

    if (model.responseMeta.parameters) {
        $.each(model.responseMeta.parameters, function(paramkey, paramval) {
            if (paramkey !== 'grading_scale') {
                requestData[paramkey] = paramval;
            } else {
                requestData[paramkey] = paramval.grading_scale_id;
            }
        });
    }

    requestData['display_term_bin_type_id'] = $('[name="display_term_bin_type_id"]').val();
    return requestData;
}
