import * as utils from '../utils';
import AggregateGradeModel from './grade';

export default function AggregateCourseGradeModel() {
    return AggregateGradeModel.apply(this); // super();
}

// inheritance! give sub-class the prototype members of the super class and then override with the child methods
utils.inheritPrototype(AggregateGradeModel, AggregateCourseGradeModel, {
    createRawTableRow: function(dataRow) {
        var self = this;

        return [
                '',
                utils.valueToLink('student_id', dataRow.student_id, dataRow.student_name),
                dataRow.student_id,
                dataRow.school_id_code,
                dataRow.sis_id,
                dataRow.state_id,
                dataRow.homeroom || '',
                dateStringToDisplay(dataRow.date),
                utils.valueToLink('course_id', dataRow.course_id, dataRow.course_name || ''),
                self.gradingScale.is_pct == 0 ? dataRow.score.toFixed(4) : (dataRow.score.toFixed() + '%'),
                dataRow.department_name || '',
                dataRow.term_bin_name
            ];
    },
    createRawTableTotalRow: function(response) {
        var self = this,
            totals = [];

        totals.length = 11;
        if (response.success) {
            var summary = response.meta.summary,
                totalRows = summary.total_rows,
                i = 0;
            totals[i++] = self.createRawTableUniqueCell(summary.student_uniq, totalRows);
            totals[i++] = self.createRawTableUniqueCell(summary.student_uniq, totalRows);
            totals[i++] = self.createRawTableUniqueCell(summary.school_id_code_uniq, totalRows);
            totals[i++] = self.createRawTableUniqueCell(summary.sis_uniq, totalRows);
            totals[i++] = self.createRawTableUniqueCell(summary.state_id_uniq, totalRows);
            totals[i++] = self.createRawTableUniqueCell(summary.homeroom_uniq, totalRows);
            i++; // skip date
            totals[i++] = self.createRawTableUniqueCell(summary.course_uniq, totalRows);
            totals[i++] = self.createRawTableAvgScoreCell(summary.score_sum, totalRows);
            totals[i++] = self.createRawTableUniqueCell(summary.department_uniq, totalRows);
            totals[i++] = self.createRawTableUniqueCell(summary.term_bin_uniq, totalRows);
        }

        return totals;
    }
});