import * as utils from '../utils';

export function init() {
    $(window)
        .on('popstate', showOrHideSharingBtn)
        .trigger('popstate');

    // remake popover with on after handler
    $('.share-report-btn')
        .removeClass('tss-popover')
        .tssPopover({
            onAfterClick: loadUsersAndUserGroups
        });

    $('.share-report-popover')
        .on('click', '.share-cancel', cancelShare)
        .on('click', '.share-submit', submitShare)
        .on('click', '.share-unshare', submitUnshare)
        .on('click', '.share-unshare-all', submitUnshareAll)
        .on('keyup', '[name="message"]', countCharsLeft);

    // initally setup max chars on message,
    // determined by maxlength prop on textarea
    setupMaxCharsAllowed();

    $('body').on('saved-report-clicked', () => $('.shared-to-list').html(''));
}

function showOrHideSharingBtn() {
    var shareReportBtn = $('.share-report-btn');
    var canShare = canShareCurrentReport();

    shareReportBtn
        .addClass('disabled')
        .attr('title', 'Save your report first and then you can share it.');

    if (canShare) {
        shareReportBtn
            .removeClass('disabled')
            .attr('title', '');
    }
}

function canShareCurrentReport() {
    return !!utils.getReportIdFromUrl();
}

function loadUsersAndUserGroups() {
    // get shared to
    if (!$('.shared-to-list *').length) {
        var analysisReportId = utils.getReportIdFromUrl();
        $.get('/api/v1/analysis-reports/' + analysisReportId + '/shared-to-users')
            .then(_.property('results.users'))
            .then(renderSharedToList);
    }

    // only fill list of students once
    if (!$('.share-report-popover').find('select option').length) {
        var userPromise = $.get('/api/v1/users/all/non-student')
        .then(_.property('results.users'))
        .then(filterActive);

        var userGroupPromise = $.get('/api/v1/user-groups/all')
            .then(_.property('results.user_groups'))
            .then(filterActive);

        $.when(userPromise, userGroupPromise)
            .then(buildOptionHtml)
            .then(appendAndCreateSelect);
    }
}

function renderSharedToList(users) {
    var html = utils.compileTemplateWithId('shared-to-user-list-template', { users });
    $('.shared-to-list').html(html);
}

function filterActive(collection) {
    return _.filter(collection, { active: '1' });
}

function buildOptionHtml(users, userGroups) {
    var userOptions = _.map(users, user => {
        return `<option value="user.${user.user_id}">${user.display_name}</option>`;
    });

    var userGroupOptions = _.map(userGroups, userGroup => {
        return `<option value="user_group.${userGroup.user_group_id}">${userGroup.display_name}</option>`;
    });

    return `<optgroup label="Users">${userOptions.join('')}</optgroup>
            <optgroup label="User Groups">${userGroupOptions.join('')}</optgroup>`;
}

function appendAndCreateSelect(html) {
    $('.share-report-popover').find('select').html(html).trigger('refresh-options');
}

function cancelShare() {
    $(this).trigger('gaEvent');

    // reset form elements and trigger click on share button to toggle popover
    $('.share-report-popover').find('select').trigger('clear');
    $('.share-report-popover').find('textarea').val('');
    $('.share-submit').html('Share').prop('disabled', false);
    $('.share-report-btn').trigger('click')
}

 function submitShare() {
    $(this).trigger('gaEvent');
    $(this).html('Sharing...').prop('disabled', true);

    var shareToErrorAlert = $('.share-error');
    var shareTo = $('[name="shareto"]').val();
    var message = $('[name="message"]').val();

    // verify input
    shareToErrorAlert.hide();
    if (!shareTo || !shareTo.length) {
        shareToErrorAlert.show();
        return;
    }

    var userIds = _.chain(shareTo)
        .filter(val => _.startsWith(val, 'user.'))
        .map(val => _.replace(val, 'user.', ''))
        .value();

    var userGroupIds = _.chain(shareTo)
        .filter(val => _.startsWith(val, 'user_group.'))
        .map(val => _.replace(val, 'user_group.', ''))
        .value();

    var postData = {
        share_to_ids: userIds,
        share_to_group_ids: userGroupIds,
        message: message
    };

    var reportId = utils.getReportIdFromUrl();

    $.post('/api/v1/analysis-reports/' + reportId + '/share', postData)
        .then(displaySuccessMessage)
        .then(fetch("/totango/shareReport/Analysis"))
        .then(() => $('.shared-to-list').html(''))
        .fail(displayErrorMessage)
        .always(cancelShare); // clear sharing popover
}

function submitUnshare() {
    var $this = $(this);
    var parentLi = $this.parents('li').first();
    var reportId = utils.getReportIdFromUrl();
    var sharedReportId = $this.data('shared_report_id');
    var userName = parentLi.find('.user-name').text();

    return $.ajax({
        method: 'PUT',
        url: `/api/v1/analysis-reports/${reportId}/share/${sharedReportId}`,
        data: { active: 0 }
    })
    .then(_.partial(displaySuccessUnshareMessage, userName))
    .then(() => parentLi.remove())
    .fail(displayUnshareErrorMessage);
}

function submitUnshareAll() {
    var reportId = utils.getReportIdFromUrl();

    return $.ajax({
        method: 'PUT',
        url: `/api/v1/analysis-reports/${reportId}/share`,
        data: { active: 0 }
    })
    .then(displaySuccessUnshareAllMessage)
    .then(() => $('.shared-to-list').html(''))
    .fail(displayUnshareErrorMessage);
}

function setupMaxCharsAllowed() {
    var maxLength = $('[name="message"]').attr('maxlength');
    $('.chars-left').text(maxLength);
    $('.chars-allowed').text(maxLength);
}

function countCharsLeft(event) {
    var length = $(this).val().length;
    var left = $(this).attr('maxlength') - length;
    $('.chars-left').text(left);
    if (left == 0) {
        $('.chars-left').addClass('text-red');
    } else {
        $('.chars-left').removeClass('text-red');
    }
}

function displayErrorMessage() {
    Growl.error({ message: 'We ran into an issue when trying to share this report. Please try again later.' });
}

function displaySuccessMessage(data) {
    var count = _.get(data, 'results.shared_reports.length');
    Growl.success({ message: 'You have successfully shared the report with ' + _.pluralize('user', count, true) + '.' });
}

function displaySuccessUnshareMessage(name) {
    Growl.success({ message: 'You have successfully unshared the report with ' + name + '.' });
}

function displaySuccessUnshareAllMessage() {
    Growl.success({ message: 'You have successfully unshared the report with all users.' });
}

function displayUnshareErrorMessage() {
     Growl.error({ message: 'We ran into an issue when trying to unshare this report. Please try again later.' });
}