import * as utils from '../utils';
import * as chart from '../chart';
import * as rawData from '../rawdata';
import * as pivot from '../pivottable';

export default init;

var chartDataPromise;

function init() {
    $('ul.analysis-tabs').tssTabs();

    $('.edit-report').on('click', editReport);
    $('.refresh-report').on('click', refreshReport);

    $('.report-viewer')
        .on('load-report', loadChartData)
        .on('click', '[data-tab="pivot"]', loadPivotData)
        .on('click', '[data-tab="records"]', loadRawData);

    $('body').on('click', '.pivotTableDownload', function(e) {
        return pivot.getPivotTableModel()
            ? pivot.getPivotTableModel().download(e, AnalysisView.studentLabel)
            : false;
    });
}

function editReport() {
    var analysisReportId = utils.getReportIdFromUrl();

    window.location.href = `/analysis?analysis_report_id=${analysisReportId}`;
}

function refreshReport() {
    $('.refresh-report').addClass('refreshing').find('i').addClass('icon-spin');

    var visual = 'table';
    var activeTab = getActiveTab();
    if (activeTab.text() == 'Chart') {
        visual = 'chart';
    }

    Growl.info({ message: `The ${visual} will refresh automatically when finished processing.` });

    var analysisReportId = utils.getReportIdFromUrl();
    $.post('/api/v1/analysis-reports/' + analysisReportId + '/refresh')
        .then(refreshReportSuccess)
        .fail(refreshReportFailed)
        .always(refreshReportFinished);
}

function refreshReportSuccess() {
    var analysisReportId = utils.getReportIdFromUrl();
    var activeTab = getActiveTab();

    if (activeTab.text() == 'Chart') {
        loadChartData(null, analysisReportId);
    } else if (activeTab.text() == 'Pivot Table') {
        clearLastReport();
        $('[data-tab="pivot"] a').trigger('click');
    } else {
        clearLastReport()
        $('[data-tab="records"] a').trigger('click');
    }
}

function refreshReportFailed() {
    Growl.error({ message: 'There was an issue refreshing the report. Please try again later.' });
}

function refreshReportFinished() {
   return $('.refresh-report').removeClass('refreshing').find('i').removeClass('icon-spin')
}

function loadChartData(event, reportId) {
    if (!reportId) {
        return showNoReportIndicator();
    }

    clearLastReport();
    showLoadingIndicatorUntil('Loading Chart Data', () => !!$('.data-state .chart svg > *').length);

    // kill the last promise, so we dont get two charts
    if (chartDataPromise && chartDataPromise.abort) {
        chartDataPromise.abort();
    }

    // must define promise and "then" chain as two separate ops because jQuery is weird
    chartDataPromise = $.get('/api/v1/analysis-reports/' + reportId + '/chart-data');
    chartDataPromise
        .then(_.partial(updateLoadingStateMessage, 'Rendering Chart'))
        .then(setRawDataHeaders)
        .then(setReportPageTitle)
        .then(setReportLastRun)
        .then(setReportDetails)
        .then(setDefaultChartControls)
        .then(renderChart)
        .then(_.partial(activateTab, 'chart'))
        .fail(showErrorIndicator);
}

function loadPivotData(event) {
    var tab       = $(this);
    var tabName   = tab.data('tab');
    var tabPane   = $(`#${tabName}`);
    var dataModel = $('.report-viewer').data('model');
    var reportId  = utils.getReportIdFromUrl();

    // only create pivot once
    if (!tabPane.find('.pivotTableWrapper:empty').length || !reportId) {
        return;
    }

    // set width so that we can scroll horizontally
    tabPane.css('width', tabPane.width());

    // show loading indicator, util table exists in dom
    showLoadingIndicatorUntil('Loading Pivot Table Data', () => !!tabPane.find('table').length);

    // get pivot data, update message, then draw table
    pivot.fetchPivotDataForReport(reportId)
        .then(_.partial(updateLoadingStateMessage, 'Rendering Pivot Table'))
        .then(_.partial(pivot.createTableFromResults, tabPane))
        .fail(showErrorIndicator);
}

function loadRawData(event, triggeredViaChartClick = false) {
    var tab       = $(this);
    var tabName   = tab.data('tab');
    var tabPane   = $(`#${tabName}`);
    var dataModel = $('.report-viewer').data('model');

    // if the table is NOT empty, dont re-render unless a chart click initiated the raw data tab.
    // Chart clicks update model values making the returned records differ from previous records result.
    if (!triggeredViaChartClick && !tabPane.find('#tableWrapper:empty').length) {
        return;
    }

    // show loading indicator, until we have a number of pages
    showLoadingIndicatorUntil('Loading Raw Data', () => !!$('.tablesorter-pager .page-number input').val());

    // create pagable datatable
    rawData.createOrUpdateDataTable(dataModel);
}

function showNoReportIndicator() {
    $('div.state-panel').hide();
    $('.analysis-empty-state').show();
}

function showErrorIndicator(jqXHR, error, statusMessage) {
    $('div.state-panel').hide();

    var standardStatusMessages = [
        'Internal Server Error',
        'Not Found',
        'Unauthorized'
    ];

    // if we have a custom status message, not a standard message
    if (!_.includes(standardStatusMessages, statusMessage)) {
        $('.analysis-error-state .custom-message').html(statusMessage);
        $('.analysis-error-state .default-message').hide();
    } else {
        $('.analysis-error-state .custom-message').html('');
        $('.analysis-error-state .default-message').show();
    }

    $('.analysis-error-state').show();
}

function showLoadingIndicatorUntil(message, until = _.noop) {
    $('div.state-panel').hide();
    $('.analysis-loading-state').show().find('h2').html(message);
    $('.data-state').show().css('visibility', 'hidden');

    var waiter = setInterval(() => {
        // keep checking every 250ms to see if our util function is true,
        // then show the report and clear the interval.
        // wait 500ms so animation is not jerky
        if (until()) {
            clearInterval(waiter);
            setTimeout(showReport, 500);
        }
    }, 250);
}

function clearLastReport() {
    $('#chart, #records #tableWrapper, #pivot .pivotTableWrapper').html('');
    refreshReportFinished(); //reset refresh button
}

function updateLoadingStateMessage(message, response) {
    $('.analysis-loading-state').show().find('h2').html(message);
    return response;
}

function showReport() {
    $('div.state-panel').hide();
    $('.data-state').css('visibility', 'visible').show();
}

function setRawDataHeaders(response) {
    rawData.setHeaders(response.meta.headers);
    return response;
}

function setReportPageTitle(response) {
    $('.report-viewer-visualization .report-title').html(response.meta.report_name);
    return response;
}

function setReportLastRun(response) {
    var runDate = _.get(response, 'meta.run_date');
    var formattedRunDate = moment.utc(runDate).fromNow();

    $('.refresh-report')
        .attr('title', `Refresh Report - Last run ${formattedRunDate}`);

    return response;
}

function setReportDetails(response) {
    var data = { run_date: _.get(response, 'meta.run_date') };

    $('.report-details')
        .removeClass('tss-popover')
        .tssPopover({
            onAfterClick: _.partial(loadAnalysisReportAndMergeWithData, data)
        });

    return response;
}

function loadAnalysisReportAndMergeWithData(extraData, popover) {
    var popoverContents = popover.find('.popover-contents');

    // get report id based on report link href, then find report
    var analysisReportId = utils.getReportIdFromUrl();

    // get analysis report and make popover
    utils.getAnalysisReportById(analysisReportId)
        .then(analysisReportData => _.merge(analysisReportData, extraData))
        .then(_.partialRight(compilePopoverTemplateForReportAndAppend, popoverContents));
}

function compilePopoverTemplateForReportAndAppend(report, popoverContents) {
    var popoverHtml = utils.compileTemplateWithId('report-details-popover-template', { report });
    popoverContents.html(popoverHtml);
}

function setDefaultChartControls(response) {
    // set default controls which are stored in analysis report state object
    var controls = _.get(response, 'meta.charts[0]');

    chart.setDefaultControls(controls);

    return response;
}

function renderChart(response) {
    var pivotValue   = _.get(response, 'meta.charts[0].pivot');
    var chartElement = $('.report-viewer-visualization #chart');
    var tabToActivate = 'records';
    var triggerViaChartClick = true;

    // we get the options from data attrs (php vars stored in attrs) and add after element click custom function
    var options      = _.extend({}, {'onAfterElementClick': _.partial(activateTab, tabToActivate, triggerViaChartClick)}, chartElement.data());
    var uri          = '/api/v1/analysis-reports/' + utils.getReportIdFromUrl() + '/records';
    var dataModel    = chart.getDataModelForResponse(response, options, uri);

    // store data model on viewer for later
    // we do this so all tabs share the same model. So clicking a chart
    // element updates the model and renders the right raw records.
    $('.report-viewer').data('model', dataModel);

    // append hidden input containing pivot value
    chartElement.append('<input type="hidden" name="pivot" value="' + pivotValue + '" />');

    // build chart in element
    return chart.buildChartForDataModelInElement(dataModel, chartElement);
}

function getActiveTab() {
    return $('.analysis-tabs li.active');
}

function activateTab(tab, ...args) {
    $('[data-tab="' + tab + '"] a').trigger('click', ...args);
}
