import * as utils from '../utils';
import AggregateSimpleModel from './simple';

export default function AggregateDemeritModel() {
    return $.extend(AggregateSimpleModel.apply(this), {
       datasetDisplay: 'behaviors',
    });
}

utils.inheritPrototype(AggregateSimpleModel, AggregateDemeritModel, {
    augmentPointValue: function(value, v) {
        var self = this;

        AggregateDemeritModel.prototype.parent.augmentPointValue.call(self, value, v);

        value.amount = (v) ? v.amount : 0;
        value.signed_cash_value = (v) ? v.cash : 0;
        value.cash_value = Math.abs(value.signed_cash_value);
        value.signed_demerit_value = (v) ? v.demerits : 0;
        value.demerit_value = Math.abs(value.signed_demerit_value);
        value.signed_detention_value = (v) ? v.detentions : 0;
        value.detention_value = Math.abs(value.signed_detention_value);
        value.signed_lunch_detention_value = (v) ? v.lunch_detentions : 0;
        value.lunch_detention_value = Math.abs(value.signed_lunch_detention_value);
        value.signed_third_detention_value = (v) ? v.third_detentions : 0;
        value.third_detention_value = Math.abs(value.signed_third_detention_value);

        return value;
    },
    augmentTooltip: function(e, graph) {
        var dc = nv.radioVal(graph.showDataControls()),
            key = 'signed_' + dc.key,
            point = e.point;

        return key in point ? '<p>' + utils.formatNumber(point[key]) + ' ' + dc.label + '</p>' : '';
    },
    augmentControls: function(controls) {
        var self = this;

        AggregateDemeritModel.prototype.parent.augmentControls.call(self, controls);

        controls.dataControls = [
            {label: 'Count', key: 'value'},
            {label: 'Amount', key: 'amount'},
            {label: self.options.cash_title, key: 'cash_value'},
        ];

        if (self.options.has_demerits) {
            controls.dataControls.push({label: self.options.demerit_title, key: 'demerit_value'});
        }

        if (self.options.has_eod_detention) {
            controls.dataControls.push({label: self.options.detention_title, key: 'detention_value'});
        }

        if (self.options.has_lunch_detention) {
            controls.dataControls.push({label: self.options.lunch_detention_title, key: 'lunch_detention_value'});
        }

        if (self.options.has_third_detention) {
            controls.dataControls.push({label: self.options.third_detention_title, key: 'third_detention_value'});
        }
    },
    createRawTableRow: function(dataRow) {
        var self = this;

        return [
                '',
                utils.valueToLink('demerit_type_id', dataRow.demerit_id, dataRow.demerit_type_name),
                dataRow.demerit_group_name || 'None',
                dataRow.amount,
                dataRow.cash_value,
                dataRow.demerits,
                dataRow.detentions,
                dataRow.lunch_detentions,
                dataRow.third_detentions,
                dateStringToDisplay(dataRow.date),
                utils.valueToLink('staff_member_id', dataRow.staff_member_id, dataRow.staff_member_name || ''),
                utils.valueToLink('location_id', dataRow.location_id, dataRow.location_name || ''),
                utils.valueToLink('student_id', dataRow.student_id, dataRow.student_name),
                dataRow.comments || '',
                dataRow.student_id,
                dataRow.school_id_code,
                dataRow.sis_id,
                dataRow.state_id,
                dataRow.grade_level_name,
                dataRow.homeroom || '',
                dataRow.gender || '',
                dataRow.lunch_status || '',
                dataRow.ethnicity || '',
                dataRow.zip || ''
            ];
    },
    createRawTableTotalRow: function(response) {
        var self = this,
            totals = [];

        totals.length = 22;
        if (response.success) {
            var summary = response.meta.summary,
                totalRows = summary.total_rows,
                i = 0;
            totals[i++] = self.createRawTableUniqueCell(summary.demerit_type_uniq, totalRows);
            totals[i++] = self.createRawTableUniqueCell(summary.demerit_group_uniq, totalRows);
            totals[i++] = self.createRawTableTotalCell(summary.amount_sum);
            totals[i++] = self.createRawTableTotalCell(summary.cash_value_sum);
            totals[i++] = self.createRawTableTotalCell(summary.demerits_sum);
            totals[i++] = self.createRawTableTotalCell(summary.detentions_sum);
            totals[i++] = self.createRawTableTotalCell(summary.lunch_detentions_sum);
            totals[i++] = self.createRawTableTotalCell(summary.third_detentions_sum);
            i++; // skip date
            totals[i++] = self.createRawTableUniqueCell(summary.staff_member_uniq, totalRows);
            totals[i++] = self.createRawTableUniqueCell(summary.location_uniq, totalRows);
            totals[i++] = self.createRawTableUniqueCell(summary.student_uniq, totalRows);
            totals[i++] = self.createRawTableUniqueCell(summary.comments_uniq, totalRows);
            totals[i++] = self.createRawTableUniqueCell(summary.student_uniq, totalRows);
            totals[i++] = self.createRawTableUniqueCell(summary.school_id_code_uniq, totalRows);
            totals[i++] = self.createRawTableUniqueCell(summary.sis_uniq, totalRows);
            totals[i++] = self.createRawTableUniqueCell(summary.state_id_uniq, totalRows);
            totals[i++] = self.createRawTableUniqueCell(summary.grade_level_uniq, totalRows);
            totals[i++] = self.createRawTableUniqueCell(summary.homeroom_uniq, totalRows);
            totals[i++] = self.createRawTableUniqueCell(summary.gender_uniq, totalRows);
            totals[i++] = self.createRawTableUniqueCell(summary.lunch_status_uniq, totalRows);
            totals[i++] = self.createRawTableUniqueCell(summary.ethnicity_uniq, totalRows);
            totals[i++] = self.createRawTableUniqueCell(summary.zip_uniq, totalRows);
        }

        return totals;
    }
});
