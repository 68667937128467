import * as utils from '../utils';

export function init() {
    $('.save-report-btn')
        .removeClass('tss-popover')
        .tssPopover({
            onAfterClick: updatePopoverButtons
        });

    $('[name="dataset"]')
        .on('change', handleDatasetChange)
        .trigger('change');

    $('.save-report-popover')
        .on('click', '.save-submit', submitSave)
        .on('click', '.update-submit', submitUpdate)
        .on('click', '.delete-submit', submitDelete)
        .on('click', '.save-cancel', closePopover);
}

function updatePopoverButtons() {
    var reportId = utils.getReportIdFromUrl();
    var ownedByCurrentUser = $('[name="report_owner"]').val() == $('[name="current_user"]').val();
    var saveBtn = $('.save-report-popover').find('.save-submit');
    var updateBtn = $('.save-report-popover').find('.update-submit');
    var deleteBtn = $('.save-report-popover').find('.delete-submit');

    saveBtn.html('Save').attr('title', '');
    updateBtn.hide();
    deleteBtn.hide();

    if (reportId) {
        saveBtn
            .html('Save as New')
            .attr('title', 'Create a new report based on the current view.');
    }

    if (ownedByCurrentUser) {
        deleteBtn.css('display', 'inline-block');
        updateBtn.css('display', 'inline-block');
    }
}

function handleDatasetChange(event) {
    var $this = $(this)
    var saveReportBtn = $('.save-report-btn');

    saveReportBtn
        .addClass('disabled')
        .attr('title', 'Create a new report and load the data first--then you can save it.');
}

function submitSave(event) {
    $(this).trigger('gaEvent');

    var saveErrorAlert = $('.save-error');
    saveErrorAlert.hide();

    var data = getReportData(event);
    data['analysis_report_id'] = null; // make sure its null for saving

    // verify we have a name
    if (!data.name) {
        saveErrorAlert.show();
        return;
    }

    return $.ajax({
        method: 'POST',
        url: '/api/v1/analysis-reports',
        data: data
    }).then(fetch("/totango/saveReport/Analysis"))
    .then(updateUrl)
    .then(displaySuccessMessage)
    .then(insertIntoSavedReportList)
    .then(updateReportOwner)
    .then(updateReportName)
    .then(triggerShowOrHideSharingBtn)
    .always(closePopover);
}

function submitUpdate(event) {
    $(this).trigger('gaEvent');

    var saveErrorAlert = $('.save-error');
    saveErrorAlert.hide();

    var data = getReportData(event);

    // verify we have a name
    if (!data.name) {
        saveErrorAlert.show();
        return;
    }

    return $.ajax({
        method: 'PUT',
        url: `/api/v1/analysis-reports/${data.analysis_report_id}`,
        data: data
    })
    .then(displaySuccessMessage)
    .then(updateSavedReportListItem)
    .then(updateReportName)
    .always(closePopover);
}

function submitDelete(event) {
    $(this).trigger('gaEvent');

    var analysisReportId = utils.getReportIdFromUrl();

    return $.ajax({
        method: 'PUT',
        url: `/api/v1/analysis-reports/${analysisReportId}`,
        data: { 'active': 0 }
    })
    .then(displaySuccessDeleted)
    .then(resetViewState)
    .always(closePopover);
}

function getReportData(event) {
    var state = AnalysisView.getLink(event);
    var schoolId = AnalysisView.getSchoolId();
    var loggedInUserId = AnalysisView.getLoggedInUserId();
    var name = $('[name="report_name"]').val();
    var groupName = $('[name="report_group_name"]').val();
    var analysisReportId = utils.getReportIdFromUrl();

    function preserveUndefined(k, v) {
        if (v === undefined) {
            return null;
        }
        return v;
    }

    return {
        analysis_report_id: analysisReportId,
        name: name,
        state: JSON.stringify(state, preserveUndefined),
        school_id: schoolId,
        created_by_id: loggedInUserId,
        report_group_name: groupName
    };
}

function closePopover() {
    $('.save-report-btn').trigger('click')
}

function updateUrl(response) {
    var reportId = _.get(response, 'results.obj.analysis_report_id');
    utils.updateBrowserHistory(reportId);
    return response;
}

function insertIntoSavedReportList(response) {
    var groupName = _.get(response, 'results.obj.report_group_name', ''),
        cleanedGroupName = groupName.replace(", ", "-").toLowerCase() || 'ungrouped',
        savedReportsAccordion = $(".saved-reports-accordion"),
        groups = savedReportsAccordion.find('.saved-reports-group'),
        group = groups.filter(function(idx) {
            return $(this).data('group-id') == cleanedGroupName;
        });

    if (!group.length) {
        savedReportsAccordion.accordion('destroy'); // reset the DOM elements to pre .accordion() state
        $('.saved-reports-popover .empty-state').remove();

        var header = $('<h3>').html(groupName);
        
        group = $('<div>')
            .addClass('saved-reports-group')
            .data('group-id', cleanedGroupName);

        savedReportsAccordion.prepend(group);
        savedReportsAccordion.prepend(header);
    }

    group.prepend(response.results.row);
    savedReportsAccordion.accordion({
        collapsible: true,
        heightStyle: 'content',
        active: groups.index(group) || 0
    });

    return response
}

function updateSavedReportListItem(response) {
    var reportId = _.get(response, 'results.obj.analysis_report_id');
    var reportName = _.get(response, 'results.obj.display_name');
    $('.saved-reports-accordion').find('a[href*="analysis_report_id=' + reportId + '"]').html(reportName);
    return response;
}

function removeSavedReportFromList(response) {
    var reportId = _.get(response, 'results.obj.analysis_report_id');
    $('.saved-reports-accordion').find('a[href*="analysis_report_id=' + reportId + '"]').remove();
    return response;
}

function updateReportOwner(response) {
    var currentUser = $('[name="current_user"]').val();
    $('[name="report_owner"]').val(currentUser);
    return response;
}

function updateReportName(response) {
    var name = _.get(response, 'results.obj.name');
    $('.report-name').text(name);
    $('.report-name-container').show();
}

// After saving we update the url with the analysis_report_id
// Once we have a saved report, we can share.
// Triggering "popstate" triggers a handler in the 'sharing.js'
// file to either show or hide the button, in this case always showing since we just saved.
function triggerShowOrHideSharingBtn() {
    $(window).trigger('popstate');
}

function resetViewState() {
    var url = window.location.pathname;
    setTimeout(() => window.location.href = url, 2500);
}

function displayErrorMessage() {
    Growl.error({ message: 'We ran into an issue when trying to save this report. Please try again later.' });
}

function displaySuccessMessage(response) {
    Growl.success({ message: 'You have successfully saved the report!' });
    return response;
}

function displaySuccessDeleted(response) {
    Growl.success({ message: 'You have successfully deleted the report!' });
    return response;
}