import * as models from './models';

export function getReportIdFromUrl(href = window.location.href) {
    var reg = new RegExp('[?&]analysis_report_id=([^&#]*)', 'i');
    var string = reg.exec(href);
    return string ? string[1] : null;
}

export function updateBrowserHistory(analysisReportId, baseUrlPath = window.location.pathname) {
    var url = analysisReportId
        ? baseUrlPath + '?analysis_report_id=' + analysisReportId
        : baseUrlPath;

    history.pushState({}, '', url);
}

export function getAnalysisReportById(reportId) {
    return $.get('/api/v1/analysis-reports/' + reportId)
        .then(_.property('results.analysis_report'));
}

export function resolveLinks(object, linkKeys) {
    var promises = _.chain(object.links)
        .pick(linkKeys)
        .map((link, idx) => {
            return $.get(link)
                .then(reponse => {
                    object[idx] = reponse.results;
                });
        })
        .value();

    return $.when.apply($, promises)
        .then(_.constant(object));
}

export function compileTemplateWithId(id, data) {
    var source   = $("#" + id).html();
    var template = Handlebars.compile(source);
    return template(data)
}

export function getDataModelForDataset(dataset) {
    if (dataset == 'assessments') {
        return new models.AggregateAssessmentModel();
    } else if (dataset == 'assessment_students') {
       return new models.AggregateAssessmentStudentModel();
    } else if (dataset == 'objectives') {
        return new models.AggregateObjectiveModel();
    } else if (dataset == 'course_grades') {
        return new models.AggregateCourseGradeModel();
    } else if (dataset == 'students') {
        return new models.AggregateStudentModel();
    } else if (dataset == 'absences') {
        return new models.AggregateAbsenceModel();
    } else if (dataset == 'demerits') {
        return new models.AggregateDemeritModel();
    } else if (dataset == 'communications') {
        return new models.AggregateCommunicationModel();
    } else if (dataset == 'incidents') {
        return new models.AggregateIncidentModel();
    } else if (dataset == 'incident_students') {
        return new models.AggregateIncidentStudentModel();
    } else {
        return new models.AggregateSimpleModel();
    }
}

export function formatNumber(number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function valueToLink(field, id, text) {
    if (id === '_totals') {
        //pivot table totals row
        return text;
    }
    if (!id || !isNumber(id)) {
        //id is empty, 0, undefined or null
        return text;
    }

    text = text || id;

    if (field == 'student_id') {
        text = '<a href="/student/' + id + '" tooltip>' + text + '</a>';
    } else if (field == 'staff_member_id') {
        text = '<a href="/staffmember/' + id + '" tooltip>' + text + '</a>';
    } else if (field == 'demerit_type_id') {
        text = '<a href="/demerit/edit/' + id + '">' + text + '</a>';
    } else if (field == 'assessment_id') {
        text = '<a href="/assessment/' + id + '">' + text + '</a>';
    } else if (field == 'course_id' || field == 'assessment.course_id') {
        text = '<a href="/course/' + id + '">' + text + '</a>';
    } else if (field == 'incident_id') {
        text = '<a href="/incident/' + id + '">' + text + '</a>';
    } else if (field == 'absence_id') {
        text = '<a href="/absences?absence_id=' + id + '">' + text + '</a>';
    } else if (field == 'communication_id') {
        text = '<a href="/communications?communication_id=' + id + '">' + text + '</a>';
    }

    return text;
}

export function inheritPrototype(parentObject, childObject, childPrototype) {
    var copyOfParent = Object.create(parentObject.prototype), // clone parent
        addToChildPrototype = function(k, v) { childObject.prototype[k] = v; };

    $.each(copyOfParent, addToChildPrototype); // add parent methods to child
    $.each(childPrototype || {}, addToChildPrototype); // add new or override methods for child
    $.each({constructor: childObject, parent: copyOfParent}, addToChildPrototype); // add generic methods
}

export function sortCallback(key, model) {
    return function(isAsc, chart) {
        var data = chart.data(),
            fn = sortByKey(key, isAsc, data, chart, model);
        $.each(data, function(i, v) {
            v.values.sort(fn);
        });
    };
}

function sortByKey(key, isAsc, data, chart, model) {
    var subKey = key,
        sortFn = function(v) { return v[subKey]; },
        asc = (isAsc ? 1 : -1),
        avgScoreSubKey = $.grep(chart.showDataControls(), function(v) { return !v.disabled; })[0].avgKey,
        valueSubKey = $.grep(chart.showDataControls(), function(v) { return !v.disabled; })[0].key || 'value',
        hasBreakout = $.grep($('[name="breakout"]').map(function() { return $(this).val(); }), function(v) { return v; }).length,
        sortBySeriesAnd = function(sortFn) {
            return function(a, b) {
                var seriesResult = hasBreakout ? cmp(a, b, function(o) { return o.seriesIndex; }) : 0;

                return seriesResult || (cmp(a, b, sortFn) * asc);
            }
        };

    if (model) {
        model.calculateTotals(chart); // update totals based on enabled/disabled
    }

    if (key == 'avgScore') {
        subKey = avgScoreSubKey;
    } else if (key == 'pivot') {
        var pivotTotals = {},
            keyToIndex = {};
        for (var i = getPivot(); i < data.length; i++) {
            if (data[i].bar) {
                $.each(data[i].values, function(j, o) {
                    pivotTotals[o.key] = (pivotTotals[o.key] || 0) + o[valueSubKey];
                    keyToIndex[o.key] = j;
                });
            }
        }

        sortFn = function(o) {
            var a = [pivotTotals[o.key]];

            a.push(o['totalAvgScore']);
            a.push(o.breakoutIndex);

            return a;
        };
    }

    return sortBySeriesAnd(sortFn);
}

// janky but best way to do this when on two different screens.
// is a select on report creator & hidden input on report viewer.
function getPivot() {
    return $('[name="pivot"]').val();
}

function cmp(a, b, sortFn) {
    var aVal = sortFn(a),
        bVal = sortFn(b);

    if ($.isArray(aVal)) {
        for(var i = 0; i < aVal.length; i++) {
            if (aVal[i] != bVal[i]) {
                return aVal[i] < bVal[i] ? -1 : 1;
            }
        }
        return 0;
    }
    return aVal == bVal ? 0 : (aVal < bVal ? -1 : 1);
}

export function pivotOffset(data) {
    var j = -1,
        m = data[0].length,
        y0 = [],
        pivot = Math.min(getPivot(), data.length); // FIXME adjust for hidden series better
    while (++j < m) {
        y0[j] = 0;
        for (var i = 0; i < pivot; i++) {
            y0[j] -= Math.abs(data[i][j][1]);
        }
    }
    return y0;
}

export function when(when = _.noop, then = _.noop) {
    var waiter = setInterval(() => {
        if (when()) {
            clearInterval(waiter);
            then();
        }
    }, 250);
}

export function keyById(collection, idProp = 'id') {
    return _.keyBy(collection, idProp);
}
