import * as utils from '../utils';
import AggregateSimpleModel from './simple';

export default function AggregateIncidentModel() {
    return AggregateSimpleModel.apply(this); // super();
}

// inheritance! give sub-class the prototype members of the super class and then override with the child methods
utils.inheritPrototype(AggregateSimpleModel, AggregateIncidentModel, {
    createRawTableRow: function(dataRow) {
        var self = this;

        return [
                '',
                utils.valueToLink('incident_id', dataRow.incident_id, dataRow.incident_id),
                utils.valueToLink('incident_type_id', dataRow.incident_type_id, dataRow.incident_type_name),
                dateStringToDisplay(dataRow.date),
                utils.valueToLink('staff_member_id', dataRow.staff_member_id, dataRow.staff_member_name || ''),
                dataRow.created_by_user_name,
                dataRow.incident_description

            ];
    },
    createRawTableTotalRow: function(response) {
        var self = this,
            totals = [];

        totals.length = 6;
        if (response.success) {
            var summary = response.meta.summary,
                totalRows = summary.total_rows,
                i = 0;
            totals[i++] = self.createRawTableUniqueCell(totalRows, totalRows);
            totals[i++] = self.createRawTableUniqueCell(summary.incident_type_uniq, totalRows);
            i++; // date
            totals[i++] = self.createRawTableUniqueCell(summary.staff_member_uniq, totalRows);
            totals[i++] = self.createRawTableUniqueCell(summary.created_by_uniq, totalRows);
            i++; // description
        }

        return totals;
    }
});