import * as utils from '../utils';
import AggregateGradeModel from './grade';

export default function AggregateAssessmentModel() {
    return AggregateGradeModel.apply(this); //super();
}

utils.inheritPrototype(AggregateGradeModel, AggregateAssessmentModel, {
    createRawTableRow: function(dataRow) {
        var self = this;

        return [
                '',
                utils.valueToLink('assessment_id', dataRow.assessment_id, dataRow.assessment_name),
                dateStringToDisplay(dataRow.date),
                utils.valueToLink('course_id', dataRow.course_id, dataRow.course_name || ''),
                utils.valueToLink('staff_member_id', dataRow.staff_member_id, dataRow.staff_member_name || ''),
                utils.valueToLink('assessment_type_id', dataRow.assessment_type_id, dataRow.assessment_type_name),
                dataRow.weight,
                self.gradingScale.is_pct == 0 ? dataRow.score.toFixed(4) : (dataRow.score.toFixed() + '%'),
                dataRow.curved_score.toFixed() + '%',
                dataRow.curve_comments || '',
                dataRow.comments || '',
                dataRow.term_bin_name
            ];
    },
    createRawTableTotalRow: function(response) {
        var self = this,
            totals = [];

        totals.length = 10;
        if (response.success) {
            var summary = response.meta.summary,
                totalRows = summary.total_rows;
            totals[0] = self.createRawTableUniqueCell(summary.assessment_uniq, totalRows);
            totals[2] = self.createRawTableUniqueCell(summary.course_uniq, totalRows);
            totals[3] = self.createRawTableUniqueCell(summary.staff_member_uniq, totalRows);
            totals[4] = self.createRawTableUniqueCell(summary.assessment_type_uniq, totalRows);
            totals[5] = self.createRawTableTotalCell(summary.weight_sum);
            totals[6] = self.createRawTableAvgScoreCell(summary.score_sum, totalRows);
            totals[7] = self.createRawTableAvgScoreCell(summary.curved_score_sum, totalRows);
            totals[10] = self.createRawTableUniqueCell(summary.term_bin_uniq, totalRows);
        }

        return totals;
    }

});