import * as utils from '../utils';
import AggregateGradeModel from './grade';

export default function AggregateAssessmentStudentModel() {
    return AggregateGradeModel.apply(this); //super();
}

utils.inheritPrototype(AggregateGradeModel, AggregateAssessmentStudentModel, {
    createRawTableRow: function(dataRow) {
        var self = this;

        return [
                '',
                utils.valueToLink('student_id', dataRow.student_id, dataRow.student_name),
                dataRow.student_id,
                dataRow.school_id_code,
                dataRow.sis_id,
                dataRow.state_id,
                dataRow.homeroom || '',
                utils.valueToLink('assessment_id', dataRow.assessment_id, dataRow.assessment_name),
                utils.valueToLink('assessment_type_id', dataRow.assessment_type_id, dataRow.assessment_type_name),
                dateStringToDisplay(dataRow.date),
                utils.valueToLink('course_id', dataRow.course_id, dataRow.course_name || ''),
                dataRow.section_period_name,
                utils.valueToLink('staff_member_id', dataRow.staff_member_id, dataRow.staff_member_name || ''),
                self.gradingScale.is_pct == 0 ? dataRow.score.toFixed(4) : (dataRow.score.toFixed() + '%'),
                (dataRow.score_override) ? (dataRow.score_override.toFixed() + '%') : '',
                dataRow.curved_score.toFixed() + '%',
                dataRow.department_name || '',
                dataRow.term_bin_name
            ];
    },
    createRawTableTotalRow: function(response) {
        var self = this,
            totals = [];

        totals.length = 15;
        if (response.success) {
            var summary = response.meta.summary,
                totalRows = summary.total_rows,
                i = 0;
            totals[i++] = self.createRawTableUniqueCell(summary.student_uniq, totalRows);
            totals[i++] = self.createRawTableUniqueCell(summary.student_uniq, totalRows);
            totals[i++] = self.createRawTableUniqueCell(summary.school_id_code_uniq, totalRows);
            totals[i++] = self.createRawTableUniqueCell(summary.sis_uniq, totalRows);
            totals[i++] = self.createRawTableUniqueCell(summary.state_id_uniq, totalRows);
            totals[i++] = self.createRawTableUniqueCell(summary.homeroom_uniq, totalRows);
            totals[i++] = self.createRawTableUniqueCell(summary.assessment_uniq, totalRows);
            totals[i++] = self.createRawTableUniqueCell(summary.assessment_type_uniq, totalRows);
            i++; // skip date
            totals[i++] = self.createRawTableUniqueCell(summary.course_uniq, totalRows);
            totals[i++] = self.createRawTableUniqueCell(summary.section_period_uniq, totalRows);
            totals[i++] = self.createRawTableUniqueCell(summary.staff_member_uniq, totalRows);
            totals[i++] = self.createRawTableAvgScoreCell(summary.score_sum, totalRows);
            totals[i++] = self.createRawTableAvgScoreCell(summary.score_override_sum, totalRows);
            totals[i++] = self.createRawTableAvgScoreCell(summary.curved_score_sum, totalRows);
            totals[i++] = self.createRawTableUniqueCell(summary.department_uniq, totalRows);
            totals[i++] = self.createRawTableUniqueCell(summary.term_bin_uniq, totalRows);
        }

        return totals;
    }

});