import * as utils from './utils';

var defaultControls = {};

export function getDefaultControls() {
    return defaultControls;
}

export function setDefaultControls(controls) {
    defaultControls = controls;
}

export function setDefaultControl(key, value) {
    defaultControls[key] = value;
}

export function getDataModelForResponse(response, options = {}, baseUri = null, chartTypeOverride = 'bar') {
    var chartType      = _.get(response, 'meta.charts[0].chart_type', chartTypeOverride);
    var dataset        = _.get(response, 'meta.dataset', '');
    var dataModel      = utils.getDataModelForDataset(dataset);
    var datasetTitle   = _.humanize(dataset).toLowerCase();
    var index          = 1;
    var numSeries      = 1;

    // init data model 
    dataModel.init(chartType, datasetTitle, response, index, numSeries, options);

    // if we have a base URI then set in data model
    if (baseUri) {
        dataModel.setRecordsRequestBaseUri(baseUri);
    }

    // return 
    return dataModel;
}

export function buildChartForDataModelInElement(dataModel, chartWrapper) {
    var chartType       = dataModel.chartType;
    var numSeries       = 1;
    var chartData       = dataModel.data.allSeries;
    var chartsPerSeries = isBarChart(chartType) ? 0 : 1;

    // build chart model 
    var chartModel;
    if (isBarChart(chartType)) {
        chartModel = createBarChart(chartType, dataModel);
    } else {
        numSeries = chartData.length;
        chartModel = createPieChart(chartType, dataModel);
    }

    // display many graphs for pie/donut or 1 for bar
    if (chartData.length > 1 && chartsPerSeries) {
        $.each(chartData, function(i, seriesData) {
            addGraph(chartWrapper, 'chart_'+ i, [seriesData], chartModel, numSeries, dataModel);
        });

        // redraw now that all charts are rendered
        redrawAllCharts(chartWrapper);
    } else {
        addGraph(chartWrapper, 'chart_0', chartData, chartModel, numSeries, dataModel);
    }

    // redraw on window resize
    nv.utils.windowResize(_.partial(redrawAllCharts, chartWrapper));
}

function isBarChart(chartType) {
    return _.includes(['bar', 'horizBar'], chartType);
}

function addGraph(chartWrapperElem, chartId, data, model, numGraphs, dataModel) {
    chartWrapperElem.append('<div class="chart" id="' + chartId + '"><svg></svg></div>');

    // wrap in a timeout so that js can append the chart element
    setTimeout(function () {
        renderGraph(chartWrapperElem, chartId, data, model, numGraphs, dataModel);
    }, 0);
}

function renderGraph(chartWrapperElem, chartId, data, model, numGraphs, dataModel) {
    var chartElem = chartWrapperElem.find('#' + chartId);
    var chartSvgElem = chartElem.find('svg');
    var defaultControls = getDefaultControls();

    // create default width depending on how many charts
    var width = chartWrapperElem.width();
    if (numGraphs > 1) {
        width = Math.floor(width / Math.min(4, numGraphs)) - 4;
    }

    // set height and width to make sure js can calculate positioning
    chartSvgElem.css({
        width: width,
        height: Math.min(500, width)
    });

    // store data and model on chart element
    chartElem
        .data('data', data)
        .data('model', model);

    // add the graph
    nv.addGraph(function() {
        var controls = initControls(chartSvgElem),
            chart = model(data, controls)
                .x(function(d) { return d.key })
                .label(function(d) { return d.label })
                .y(function(d) { return d.value });

        if (chart.showDataControls) {
            initControl(controls, defaultControls, 'dataControls');
            chart.showDataControls(controls.dataControls);
        }

        if (chart.showControls) {
            initControl(controls, defaultControls, 'stackedControls');
            chart.showControls(controls.stackedControls);
            dataModel.calculateTotals(chart);
        }

        if (chart.showSortControls) {
            initControl(controls, defaultControls, 'sortControls');
            initControl(controls, defaultControls, 'ascDescControls');
            chart.showSortControls(controls.sortControls)
                .showAscDescControls(controls.ascDescControls)
        }

        if (defaultControls.legend) {
            $.each(data, function(i, o) {
                var oldLegend = defaultControls.legend,
                    key = o.key,
                    val = null;

                if ($.isArray(oldLegend)) {
                    $.each(oldLegend, function(j, p) {
                        if (p.key == o.key && 'disabled' in p) {
                            val = p.disabled;
                            return false; // break;
                        }
                    });
                } else if (key in oldLegend) {
                    val = oldLegend[key]
                }

                if (val !== null) {
                    o.disabled = val;
                }
            });
            defaultControls.legend = data;
        }

        d3.select(chartSvgElem.get(0))
            .datum(data)
            .transition().duration(1200)
            .call(chart);

        chartElem.data('chart', chart);

        return chart;
    });
}

function initControls(svg) {
    if (!svg.data('controls')) {
        svg.data('controls', {
            stackedControls: [
                {label: 'Stacked', key: 'stacked'},
                {label: 'Grouped', key: 'grouped'},
            ],
            sortControls: [
                {label: 'Default', sortCallback: utils.sortCallback('breakoutIndex')},
                {label: 'A-Z', sortCallback: utils.sortCallback('label')},
            ],
            ascDescControls: [
                {label: 'Asc', isAsc: true},
                {label: 'Desc'}
            ]
        });
    }

    return svg.data('controls');
}

function initControl(controls, defaults, key) {
    var i = null,
        control = controls[key] || [],
        oldControl = defaults[key],
        model = null;

    if ($.isPlainObject(control)) {
        model = control.model;
        control = control.data;
    }

    if (key === 'legend') {
        $.each(control, function(i, o) {
            var key = o.key,
                val = null;

            if ($.isArray(oldControl)) {
                $.each(oldControl, function(j, p) {
                    if (p.key == o.key && 'disabled' in p) {
                        val = p.disabled;
                        return false; // break;
                    }
                });
            } else if (key in oldControl) {
                val = oldControl[key]
            }

            if (val !== null) {
                o.disabled = val;
            }
        });
    } else {
        if ($.isArray(oldControl)) {
            oldControl = nv.radioVal(oldControl);
            oldControl = oldControl.key || oldControl.label;
        }

        $.each(control, function(j, o) {
            if (o['key' in o ? 'key' : 'label'] === oldControl) {
                i = j;
                return false; // break;
            }
        });

        if (i !== null) {
            control[i].disabled = true;
            if (model && model.dispatch && model.dispatch.legendClick) {
                model.dispatch.legendClick(null, i); // update the chart
            } else {
                nv.radioify(control, i);
            }
        }
    }

    if (control.length) {
        setDefaultControl(key, control);
    }
}

function redrawAllCharts(chartWrapper) {
    // since we have to explicity set widths/heights on the svg element,
    // once it has been sized up, shrinking the window, the chart wrapper
    // is still the same width as the svg since it takes up its contents width.
    // So we calculate based on the main body width, minus the sidebar (report viewer)
    var mainBodyWidth = $('.mainBody').width();
    var sidebarWidth = $('.report-viewer-sidebar').length ? $('.report-viewer-sidebar').outerWidth() : 0;
    var width = mainBodyWidth - sidebarWidth;
    var numGraphs = chartWrapper.find('.chart').length;

    if (width < 100) {
        width = mainBodyWidth; // in case the sidebar has wrapped below the graphs
    }

    if (numGraphs > 1) {
        width = Math.floor(width / Math.min(4, numGraphs)) - 4;
    }

    chartWrapper.find('.chart').each(function() {
        var div = $(this),
            chart = div.data('chart'),
            data = div.data('data'),
            svg = div.find('svg');

        if (chart) {
            svg.css({
                width: width,
                height: Math.min(500, width)
            });
            d3.select(svg.get(0))
                .datum(data)
                .call(chart);
        }
    });
}

function createBarChart(chartType, dataModel) {
    return function(data, controls) {
        var chart = (chartType == 'bar' ? nv.models.linePlusMultiBarChart() : nv.models.linePlusMultiBarHorizontalChart())
            .delay(0);

        dataModel.augmentControls(controls);

        return dataModel.augmentChart(chart, data);
    }
}

function createPieChart(chartType, dataModel) {
    return function(data, controls) {
        var chart = nv.models.pieChart()
            .margin({top: 45})
            .labelThreshold(.05)
            .donut(chartType == 'donut');

        dataModel.augmentControls(controls);

        return dataModel.augmentChart(chart, data);
    }
}