import * as utils from './utils';
import * as chart from './chart';
import * as pivot from './pivottable';
import * as rawData from './rawdata';
import * as sharing from './analysis/sharing';
import * as saving from './analysis/saving';
import * as picker from './analysis/picker';

window.AnalysisView = (function() {
    'use strict';
    var _numSeries = 1,
        defaultState = {},
        dataCache = {},
        filterSetPromises,
        loadingFromStateCounter = 0,
        suspendRedraw = false,
        settings,
        dashboardColumns,
        historicalSettings,
        options,
        initialOptions,
        gradeLevelNameToSchoolIds,
        studentLabel,
        sisShortName,
        homeroomLabel,
        schoolId,
        loggedInUserId,
        counter = 0,
        oldControls;

    return {
        defaultState: defaultState,
        dataCache: dataCache,
        suspendRedraw: suspendRedraw,
        loadingFromStateCounter: loadingFromStateCounter,
        settings: settings,
        dashboardColumns: dashboardColumns,
        historicalSettings: historicalSettings,
        options: options,
        initialOptions: initialOptions,
        gradeLevelNameToSchoolIds: gradeLevelNameToSchoolIds,
        studentLabel: studentLabel,
        sisShortName: sisShortName,
        homeroomLabel: homeroomLabel,
        schoolId: schoolId,
        getSchoolId: getSchoolId,
        loggedInUserId: loggedInUserId,
        getLoggedInUserId: getLoggedInUserId,

        init: init,
        submitAnalysisData: submitAnalysisData,
        showLoadingIndicatorUntil: showLoadingIndicatorUntil,
        updateLoadingStateMessage: updateLoadingStateMessage,
        getRequestDataFromForm: getRequestDataFromForm,
        getRequestDataFromState: getRequestDataFromState,
        fillInAllFilters: fillInAllFilters,
        updateFromState: updateFromState,
        getLink: getLink,
        getChartType: getChartType,
        getChartsPerSeries: getChartsPerSeries,
        getNumSeries: getNumSeries,
        getDatasetOption: getDatasetOption,
        getDatasetTitle: getDatasetTitle,
        getBreakoutOption: getBreakoutOption,
        getSeriesBucketOption: getSeriesBucketOption,
        getBreakoutField: getBreakoutField,
        getDatasetFilters: getDatasetFilters,
        getChartDiv: getChartDiv,
        restrictOptions: restrictOptions,
        applyUniqueValues: applyUniqueValues,
        getCounter: getCounter,
        incrementCounter: incrementCounter,
        pushState: pushState,
        popState: popState
    };

    function init(opts) {
        AnalysisView.settings = settings = opts['settings'];
        AnalysisView.dashboardColumns = dashboardColumns = opts['dashboardColumns'];
        AnalysisView.historicalSettings = historicalSettings = opts['historicalSettings'];
        AnalysisView.options = options = opts['options'];
        AnalysisView.initialOptions = initialOptions = opts['initialOptions'];
        AnalysisView.gradeLevelNameToSchoolIds = gradeLevelNameToSchoolIds = opts['gradeLevelNameToSchoolIds'];
        AnalysisView.studentLabel = studentLabel = opts['studentLabel'];
        AnalysisView.sisShortName = sisShortName = opts['sisShortName'];
        AnalysisView.homeroomLabel = homeroomLabel = opts['homeroomLabel'];
        AnalysisView.schoolId = schoolId = opts['schoolId'];
        AnalysisView.loggedInUserId = loggedInUserId = opts['loggedInUserId'];

        // key grading scales by grading_scale_id for easier lookup
        var gradingScalesById = utils.keyById(AnalysisView.dataCache.grading_scales, 'grading_scale_id')
        AnalysisView.dataCache.grading_scales_by_id = gradingScalesById;

        // make tabbed interface
        $('.analysis-tabs').tssTabs();

        $('.report-creator')
            .on('click', '[data-tab="pivot"]', pivot.loadInitialPivotData)
            .on('click', '[data-tab="records"]', rawData.showAllData);

        // load report if we clicked landed on /analysis directly
        var analysisReportId = utils.getReportIdFromUrl();
        if (analysisReportId) {
            getStateForAnalysisReport(analysisReportId)
                .then(updateFromState);
        } else {
            showEmpty();
        }

        // init picker, sharing, and saving
        sharing.init();
        saving.init();
        picker.init();

        $('.filter-panes #student [name*="school_id"]').change(updateVisibleGradeLevels(gradeLevelNameToSchoolIds, $('.filter-panes #student')));
        $.each(initialOptions, fillInForm);
        setupAddAndClear();

        $('body').off('click', '.removeParent').on('click', '.removeParent', removeParent);
        $('body').on('change', '.pivotTable.filterContainer', pivot.pivotTableChange);
        $('[name="grading_scale"]').change(function() {
            setupPivotDropdown(getGradingScale());
        });
        $('body').on('click', '.pivotTableDownload', function(e) {
            $(this).trigger('gaEvent');
            return pivot.getPivotTableModel() ? pivot.getPivotTableModel().download(e, AnalysisView.studentLabel) : false;
        });
        $('body').on('click', '.pivotTableFlipLeftTop', function(e) {
            var pivotDiv = $(this).closest('#pivot > div'),
                pivotTableLeft = pivotDiv.find('[name="pivot_table_left"]'),
                pivotTableLeft2 = pivotDiv.find('[name="pivot_table_left2"]'),
                pivotTableTop = pivotDiv.find('[name="pivot_table_top"]'),
                pivotTableTop2 = pivotDiv.find('[name="pivot_table_top2"]'),
                leftVal = pivotTableLeft.val(),
                leftVal2 = pivotTableLeft2.val(),
                topVal = pivotTableTop.val(),
                topVal2 = pivotTableTop2.val();

            pivotTableLeft.val(topVal).trigger('change', true);
            pivotTableLeft2.val(topVal2).trigger('change', true);
            pivotTableTop.val(leftVal).trigger('change', true);
            pivotTableTop2.val(leftVal2).change();

            return stopPropagation(e);
        });

        var savedReportsAccordion = $(".saved-reports-accordion");
        savedReportsAccordion.accordion({ collapsible: true, heightStyle: "content" });

        $('.saved-reports-popover').on('click', '.saved-reports-group a', function(e) {
            var href = $(this).attr('href');
            var analysisReportId = utils.getReportIdFromUrl(href);

            getStateForAnalysisReport(analysisReportId)
                .then(updateFromState)
                .then(_.partial(updateBrowserAddressWithReportId, analysisReportId));

            $(this).trigger('gaEvent');
            $('body').trigger('saved-report-clicked');
            return stopPropagation(e);
        });
    }

    function getStateForAnalysisReport(analysisReportId) {
        return $.post('/analysis/report/state/' + analysisReportId)
            .then(updateReportOwner)
            .then(updateReportName)
            .then(_.property('results.obj.state'))
            .then(JSON.parse);
    }

    function updateReportOwner(response) {
        var owner = _.get(response, 'results.obj.created_by_id');
        $('[name="report_owner"]').val(owner);
        return response;
    }

    function updateReportName(response) {
        var name = _.get(response, 'results.obj.name');
        $('.report-name').text(name);
        $('.report-name-container').show();
        $('[name="report_name"]').val(name);
        $('.data-picker').data('title_override', name);
        return response;
    }

    function updateBrowserAddressWithReportId(analysisReportId) {
        utils.updateBrowserHistory(analysisReportId);
        $(window).trigger('popstate'); // show or hide sharing button

        return analysisReportId;
    }

    function submitAnalysisData(state) {
        var dataPicker = $('.data-picker');
        var chartElement = $('#chartWrapper');
        var saveReportBtn = $('.save-report-btn');
        var requestData = state && state.filters && state.filters.length
            ? getRequestDataFromState(state, 'chart')
            : getRequestDataFromForm(dataPicker, 'chart');

        // we dont need filter details unless saving report
        delete requestData['filter_details'];
        
        saveReportBtn.addClass('disabled');

        // show Loading
        showLoadingIndicatorUntil('Loading Chart Data', () => !!$('.data-state .chart svg > *').length);

        // clear visuals and open chart tab
        chartElement.html('');
        $('.pivotTableWrapper table').remove();
        $('.data-picker').data('pivotResults', null);
        $('#tableWrapper').html('');
        $('[data-tab="chart"]').trigger('click');

        // get chart data
        return $.post('/api/v1/analysis/' + requestData.dataset + '/chart-data', requestData)
            .then(_.partial(updateLoadingStateMessage, 'Rendering Chart'))
            .then(response => {

                // set headers for raw data section
                rawData.setHeaders(response.meta.headers);

                // when init is called the pivot html may not exist yet so we double check that it is set before rendering chart
                if (requestData.grading_scale_pivot_level) {
                    var option = $(`[name="pivot"] option[data-min_value="${requestData.grading_scale_pivot_level}"]`);

                    if (option.length == 1) {
                        $('[name="pivot"]').val(option.val()).data('sel', option.val());
                    }
                }

                 // build model
                var dataModel = chart.getDataModelForResponse(response, $('#chart').data(), null, getChartType());
                $('#chartWrapper').data('model', dataModel);

                return dataModel;
            })
            .then(dataModel => {
                var retVal = chart.buildChartForDataModelInElement(dataModel, chartElement);
                
                setTimeout(() => { AnalysisView.pushState() }, 10);
                
                return retVal;
            })
            .then(() => {
                updateFilterDesc(dataPicker);
            })
            .then(() => {
                saveReportBtn.removeClass('disabled').attr('title', ''); // re-enable the Save Report button
            })
            .then(() => {
                return $.post('/api/v1/analysis/' + requestData.dataset + '/uniques', requestData);
            })
            .then(response => {
                restrictOptions(response, getDatasetFilters(dataPicker), dataPicker);
            })
            .fail(displayErrorState);
    }

    function displayErrorState() {
        $('div.state-panel').hide();
        $('.analysis-error-state').show();
    }

    function showLoadingIndicatorUntil(message, until = _.noop) {
        $('div.state-panel').hide();
        $('.analysis-loading-state').show().find('h2').html(message);
        $('.data-state').show().css('visibility', 'hidden');

        var waiter = setInterval(() => {
            // keep checking every 250ms to see if our util function is true,
            // then show the report and clear the interval.
            // wait 500ms so animation is not jerky
            if (until()) {
                clearInterval(waiter);
                setTimeout(showReport, 500);
            }
        }, 250);
    }

    function showReport() {
        $('div.state-panel').hide();
        $('.data-state').css('visibility', 'visible').show();
    }

    function updateLoadingStateMessage(message, response) {
        $('.analysis-loading-state').show().find('h2').html(message);
        return response;
    }

    /**
     * This is modeled after getFilters() from tss.js... except this builds actual values
     * instead of simply gathering IDs and bool values
     * @param container
     * @return obj details -- filter details
     */
    function getFilterDetailsForDataset(container) {
        var inputs = container.find('input[name]:not(:radio), input[name]:radio:checked, select'),
            inputMultis = container.find('.multi'),
            sliderMultis = container.find('.slider[multiple]'),
            multipleDataIncludes = container.find('select[multiple][data-include!="1"]'),
            details = {};

        _.each(inputs, input => {
            var input = $(input),
                id = input.attr('name'),
                value = input.val();

            if (input.is('select')) {
                details[id] = _.map(input.find('option:selected'), _.property('text'));
            } else if (input.parents('.multi').length) {
                if ((!input.is(':checkbox') || input.is(':checked')) && value !== '') {
                    if (!(id in details)) {
                        details[id] = [];
                    }
                    details[id].push(value);
                }
            } else if (input.parents('.slider[multiple]').length) {
                details[id] = value.split(',');
            } else if (value) {
                if (id.indexOf('active') >= 0) {
                    value = value == 0 ? 'No' : value == 1 ? 'Yes' : 'Both';
                }

                details[id] = [value];
            }
        });

        // non select multi inputs (i.e., term bins)
        _.each(inputMultis, input => {
            var multi = $(input),
                checkboxes = $(":checkbox", multi),
                numCheckboxes = checkboxes.length,
                numSelected = $(":checkbox:checked", multi).length,
                name = $(':checkbox:first', multi).attr('name');
            // If there is another multi with the same name that also has
            // checkboxes selected, don't do the check to remove the filter
            if ($("[name=\"" + name + "\"]:checked").not(checkboxes).length) {
                return;
            }

            if (!numSelected) {
                delete details[name];
            } else if (numSelected == numCheckboxes) {
                details[name] = ['All'];
            }

        });

        // non select single inputs (i.e., active)
        _.each(multipleDataIncludes, input => {
            var select = $(input),
                totalOptions = $('option', select).length,
                vals = select.val(),
                numSelected = vals ? vals.length : 0,
                name = select.attr('name');

            if (!numSelected || numSelected == totalOptions) {
                delete details[name];
            }
        });

        // sliders (i.e., date)
        _.each(sliderMultis, input => {
            var slider = $(input),
                min = slider.slider('option', 'min'),
                max = slider.slider('option', 'max'),
                name = slider.find('input[name]').attr('name'),
                vals = details[name],
                staticRange = slider.data("staticRange"),
                ignoreStart = staticRange ? false : vals[0] == min,
                ignoreEnd = staticRange ? false : vals[1] == max;

            if (ignoreStart && ignoreEnd) {
                delete details[name];
                return true; // continue
            } else if (slider.data('filter_type') == 'date') {
                details[name][0] = dateStringToServer(new Date(parseInt(details[name][0])));
                details[name][1] = dateStringToServer(new Date(parseInt(details[name][1])));
            }

            if (ignoreStart) {
                details[name][0] = '';
            }

            if (ignoreEnd) {
                details[name][1] = '';
            }
        });

        // clean keys
        _.each(details, (value, key) => {
            delete details[key];
            details[cleanKey(key)] = value;
        });

        return details;
    }

    function cleanKey(key) {
        var parts = key.match(/^([\d_]*)(\D*[^_id])/);
        return parts[2]; // second capture group
    }

    function getFilterClassesForDataset(dataset) {
        var classes = [];
        switch (dataset) {
            case 'assessments':
                classes = ['assessments'];
                break;
            case 'assessment_students':
                classes = ['assessments','assessment_students','students','student_demerit_detention','student_custom_attrs'];
                break;
            case 'objectives':
                classes = ['assessments','assessment_students','objectives','students','student_demerit_detention','student_custom_attrs'];
                break;
            case 'course_grades':
                classes = ['course_grades','students','student_demerit_detention','student_custom_attrs'];
                break;
            case 'students':
                classes = ['students','student_demerit_detention','student_custom_attrs'];
                break;
            case 'demerits':
                classes = ['demerits','students','student_demerit_detention','student_custom_attrs'];
                break;
            case 'communications':
                classes = ['communications','students','student_demerit_detention','student_custom_attrs'];
                break;
            case 'absences':
                classes = ['absences','students','student_demerit_detention','student_custom_attrs'];
                break;
            case 'incidents':
                classes = ['incidents','students','student_demerit_detention','student_custom_attrs'];
                break;
            case 'incident_students':
                classes = ['incident_students','incidents','students','student_demerit_detention','student_custom_attrs'];
                break;
        }

        return _.map(classes, clazz => ({'label': clazz, 'selector': '.filter-panes #' + clazz }));
    }

    function getFilterDetails(dataset, element) {
        var filterClassObjects = getFilterClassesForDataset(dataset),
            filterDetails = {};
        _.each(filterClassObjects, object => {
            var label = element.find('[data-tab="'+ object.label +'"] a').text().replace(/[fF]ilters/, '').trim();
            label = (label != 'Result' ? label : _.titleize(_.humanize(dataset)));
            filterDetails[label] = getFilterDetailsForDataset(element.find(object.selector));
        })

        return filterDetails;
    }

    function getStudentFilterString(elements) {
        var filterStrings = _.compact(_.map(elements, (element) => getFilterString(element)));

        return _.trim(filterStrings.join('&'), '&');
    }

    function getRequestDataFromForm(element, reportType) {
        var datasetOption = getDatasetOption(element),
            pivotDiv = pivot.getPivotDiv(),
            filterClassObjects = getFilterClassesForDataset(datasetOption.val()),
            requestData = {
                dataset: datasetOption.val(),
                dimension_1: getBreakoutOption(element).val(),
                dimension_2: getSeriesBucketOption(element).val(),
                display_term_bin_type_id: $('[name="display_term_bin_type_id"]').val(),
                filter_details: getFilterDetails(datasetOption.val(), element)
            };

        // only send over filter strings for active dataset
        _.each(filterClassObjects, filter => {
            if (_.includes(['students','student_demerit_detention','student_custom_attrs'], filter['label'])) {
                requestData['student_filter'] = getStudentFilterString([
                    element.find('.filter-panes #students'),
                    element.find('.filter-panes #student_demerit_detention'),
                    element.find('.filter-panes #student_custom_attrs'),
                ]);
            } else {
                var label = _.singularize(filter['label']) + '_filter';
                requestData[label] = getFilterString(element.find(filter.selector));
            }
        });

        if (requestData.dataset == 'assessments' || requestData.dataset == 'assessment_students' ||
            requestData.dataset == 'objectives' || requestData.dataset == 'course_grades') {
            requestData.grading_scale = $('select[name="grading_scale"]').val();
            var gradingScaleLevelOption = getSelectedOption($('select[name="pivot"]'));
            requestData.grading_scale_pivot_level = gradingScaleLevelOption.data('min_value');
            if (requestData.dataset == 'objectives') {
                requestData.mastery_results = $('[name="mastery_results_' + reportType + '"]:checked').val();
            } else {
                requestData.raw_or_curved = $('[name="raw_or_curved_' + reportType + '"]:checked').val();
            }
            if (reportType == 'chart' && ! requestData.dimenson_2) {
                requestData.dimension_2 = 'grading_scale_level_id';
            }
        }

        //pivot dimensions
        if (reportType == 'pivot') {
            requestData.dimension_1 = pivot.getPivotTableLeftOption(pivotDiv).val() || '';
            requestData.dimension_2 = pivot.getPivotTableLeft2Option(pivotDiv).val() || '';
            requestData.dimension_3 = pivot.getPivotTableTopOption(pivotDiv).val() || '';
            requestData.dimension_4 = pivot.getPivotTableTop2Option(pivotDiv).val() || '';
        }
        return requestData;
    }

    function getRequestDataFromState(state, reportType) {
        var filter = state.filters[0],
            requestData = {
                dataset: filter.dataset,
                dimension_1: filter.breakout,
                dimension_2: filter.series_bucket,
                display_term_bin_type_id: $('[name="display_term_bin_type_id"]').val(),
                assessment_filter: filter.assessment_filter,
                assessment_student_filter: filter.assessment_student_filter,
                objective_filter: filter.objective_filter,
                course_grade_filter: filter.course_grade_filter,
                student_filter: filter.student_filter,
                demerit_filter: filter.demerit_filter,
                communication_filter: filter.communication_filter,
                absence_filter: filter.absence_filter,
                incident_filter: filter.incident_filter,
                incident_student_filter: filter.incident_student_filter
            };

        if (requestData.dataset == 'assessments' || requestData.dataset == 'assessment_students' ||
            requestData.dataset == 'objectives' || requestData.dataset == 'course_grades') {
            requestData.grading_scale = _.get(state, 'charts[0].grading_scale');
            requestData.grading_scale_pivot_level = _.get(state, 'charts[0].pivot');

            if (requestData.dataset == 'objectives') {
                requestData.mastery_results = $('[name="mastery_results_' + reportType + '"]:checked').val();
            } else {
                requestData.raw_or_curved = $('[name="raw_or_curved_' + reportType + '"]:checked').val();
            }
            if (reportType == 'chart' && ! requestData.dimenson_2) {
                requestData.dimension_2 = 'grading_scale_level_id';
            }
        }

        //pivot dimensions
        if (reportType == 'pivot') {
            requestData.dimension_1 = filter.pivot_left || '';
            requestData.dimension_2 = filter.pivot_left2 || '';
            requestData.dimension_3 = filter.pivot_top || '';
            requestData.dimension_4 = filter.pivot_top2 || '';
        }
        return requestData;
    }

    function fillInAllFilters(requestData) {
        $.each(requestData, function(k, v) {
            if (!k.match(/_filter$/)) return true; // continue;

            var clazz = k.replace('_filter', '') + 's';
            var filters;
            if (clazz == 'students') {
                filters = $('.filter-panes #students, .filter-panes #student_demerit_detention, .filter-panes #student_custom_attrs');
            } else {
                filters = $('.filter-panes #' + clazz);
            }

            fillInFilters(v, filters);
        });
    }
    
    function pushState() {
        AnalysisView.cachedState = AnalysisView.getLink();
    }
    
    function popState() {
        // if we've already loaded a report, reset to the config for that report
        if (AnalysisView.cachedState) {
            AnalysisView.updateFromState(AnalysisView.cachedState, true);
        } else {
            // else just reset back to the dataset picker screen for consistency
            $('.change-dataset').click();
        }
    }

    function updateFromState(state, updateUIOnly) {
        var filters = _.get(state, 'filters[0]'),
            charts = _.get(state, 'charts[0]'),
            dataPicker = $('.data-picker');

        // set the state obj so that after each filter pane is dynamically loaded it can prefill
        dataPicker.data('state', state);

        // wipe all filter panes to start from a clean slate
        dataPicker.find('.filter-panes .dynamic').html('');

        var filters = _.get(state, 'filters[0]', []);
        AnalysisView.fillInAllFilters(filters);

        // det chart defaults
        chart.setDefaultControls(charts);

        // click the dataset we want to render
        // and mark the submitted dataset
        dataPicker.find('[data-dataset="' + filters.dataset + '"]').trigger('click');
        dataPicker.find('input[name="submitted_dataset"]').val(filters.dataset).trigger('change');

        // submit filters to server unless we're just trying to update the UI
        // elements from the cachedState upon hitting Cancel
        if (!updateUIOnly) {
            submitAnalysisData(state);
        }
        
        dataPicker.find('.dataset-step').hide();
        dataPicker.find('.filters-step').show();

        // when the data picker has successfully fetched breakout by dataset
        // and then filled in necessary dropdowns
        dataPicker
                .off('breakout-and-pivot-selects-ready')
                .on('breakout-and-pivot-selects-ready', () => {
            var localState = state || AnalysisView.cachedState,
                filters = _.get(localState, 'filters[0]'),
                charts = _.get(localState, 'charts[0]');

            // set dataset select values
            if (charts) {
                $('[name="chart_type"]').val(charts.chart_type);
                $('[name="grading_scale"]').val(charts.grading_scale).data('sel', charts.grading_scale);
                $('[name="pivot"]').val(charts.pivot).data('sel', charts.pivot);
            }
            
            if (filters) {
                $('[name="breakout"]').val(filters.breakout).data('sel', filters.breakout);
                $('[name="series_bucket"]').val(filters.series_bucket).data('sel', filters.series_bucket);

                // set pivot select values
                var pivotDiv = pivot.getPivotDiv();
                pivotDiv.find('[name="pivot_table_left"]').data('sel', filters.pivot_left);
                pivotDiv.find('[name="pivot_table_left2"]').data('sel', filters.pivot_left2);
                pivotDiv.find('[name="pivot_table_top"]').data('sel', filters.pivot_top);
                pivotDiv.find('[name="pivot_table_top2"]').data('sel', filters.pivot_top2);

                // fill in pivot controls (show_growth, raw_curved, etc)
                // but make sure we're not reloading data as a result of changing
                // the pivot controls
                var pivotControls = _.pick(filters, pivot.getPivotOptions());
                AnalysisView.suspendRedraw = true;
                _.each(pivotControls, (inputVal, inputName) => {
                    if (inputName == 'raw_or_curved' || inputName == 'mastery_results') {
                        inputName += '_pivot';
                    }
                    fillInForm(inputName, inputVal, pivotDiv);
                });
                AnalysisView.suspendRedraw = false;
            }

            var fixNullVal = function() {
                if ($(this).val() === null) {
                    $(this).val('').data('sel', '');
                }
            };
            
            $('[name="chart_type"],[name="breakout"],[name="series_bucket"],[name="grading_scale"],[name="pivot"]')
                .each(hyjackSelect)
                .each(fixNullVal)
                .trigger('change');

            pivotDiv
                .find('[name*="pivot_table_"]')
                .each(hyjackSelect)
                .each(fixNullVal);
        });
    }

    function getLink(e) {
        var filters = [];
        var charts = [];
        var state = {};
        var fieldKey;
        var dataPicker = $('.data-picker');
        var pivotDiv = pivot.getPivotDiv();
        var requestData = getRequestDataFromForm(dataPicker, 'chart');

        requestData.breakout = getBreakoutOption(dataPicker).val();
        requestData.series_bucket = getSeriesBucketOption(dataPicker).val();
        requestData.pivot_left = pivot.getPivotTableLeftOption(pivotDiv).val();
        requestData.pivot_left2 = pivot.getPivotTableLeft2Option(pivotDiv).val();
        requestData.pivot_top = pivot.getPivotTableTopOption(pivotDiv).val();
        requestData.pivot_top2 = pivot.getPivotTableTop2Option(pivotDiv).val();

        $.each(pivot.getPivotOptions(), function(i, k) {
            fieldKey = k;
            if (k == 'raw_or_curved' || k == 'mastery_results') {
                fieldKey += '_pivot';
            }
            requestData[k] = pivotDiv.find('[name="' + fieldKey + '"]:checked').val();
        });

        filters.push(requestData);

        $('.chart').each(function() {
            var chart = $(this).data('chart');

            if(chart) {
                var controls = chart.allControls,
                    controlValues = {
                        chart_type: getChartType(),
                        grading_scale: $('select[name="grading_scale"]').val(),
                        pivot: getPivot()
                    };

                _.each(controls, function(v, k) {
                    var val;

                    if (v.isLegend) {
                        val = {};
                        _.each(v.data, function(o) {
                            val[o.key] = !!o.disabled;
                        });
                    } else {
                        val = nv.radioVal(v.data);
                        val = val.key || val.label;
                    }

                    controlValues[k] = val;
                });

                //maintain compability with analysis.php
                if ($('[name="raw_or_curved_chart"]:checked').val() == 'curved') {
                    controlValues.dataControls = 'curvedValue';
                } else if ($('[name="mastery_results_chart"]:checked').val() == 'last') {
                    controlValues.dataControls = 'lastValue';
                }

                charts.push(controlValues);
            }
        });

        state.charts = charts;
        state.filters = filters;

        stopPropagation(e);

        return state;
    }

    function getChartType() {
        return $('[name="chart_type"]').val();
    }

    function getChartsPerSeries() {
        return getSelectedOption($('[name="chart_type"]')).data('charts_per_series') || 0;
    }

    function getNumSeries() {
        return _numSeries;
    }

    function getCounter() {
        return counter;
    }

    function incrementCounter() {
        counter++;
    }

    function getDatasetOption(element) {
        return element.find('[name="dataset"]');
    }

    function getDatasetTitle(filterColumn, dataset) {
        return filterColumn.find('[name="dataset"]').find('option[value="' + dataset + '"]').text();
    }

    function getBreakoutOption(element) {
        return getSelectedOption(element.find('[name="breakout"]'));
    }

    function getSeriesBucketOption(element) {
        return getSelectedOption(element.find('[name="series_bucket"]'));
    }

    function getBreakoutField(filterColumn) {
        var breakoutParts = getBreakoutOption(filterColumn).val().split('.');

        return breakoutParts[breakoutParts.length - 1];
    }

    function getDatasetFilters(element) {
        var dataset = $('[name="dataset"]').val();
        var filterSets = $('.dataset[data-dataset="' + dataset + '"]').data('filter-sets');

        var filterSetSelectors = _.chain(filterSets)
            .split(',')
            .map(filterSet => `.filter-panes #${filterSet}`)
            .value();

        return element.find(filterSetSelectors.join(', '));
    }

    function getChartDiv() {
        return $('#chartWrapper');
    }

    function restrictOptions(response, datasetFilters, element) {
        if (!response || ! response.success) return;

        var uniqueValues = response.results;
        datasetFilters.closest('.data-picker').data('unique_values', uniqueValues);

        applyUniqueValues(uniqueValues, datasetFilters);
    }

    function applyUniqueValues(uniqueValues, datasetFilters, cachedFilteredValues) {
        var element = datasetFilters.closest('.data-picker'),
            filteredValues = (cachedFilteredValues || element.data('filtered_values') || {}),
            resultSet = [],
            notResultSet = [],
            checkboxResultSet = [],
            checkboxNotResultSet = [];

        time('applyUniqueValues');

        $.each(cachedFilteredValues || uniqueValues, function(k, v) {
            var input = datasetFilters.find('input, select').filter(nameFilter(k)),
                val = input.val();

            if (input.is(':checkbox')) {
                val = input.filter(':checked').val();
            }

            var doFilter = !(cachedFilteredValues ? !(k in cachedFilteredValues) : (!input.length || input.is(':radio') || val));

            if (!cachedFilteredValues) {
                filteredValues[k] = v; // get from unique values if we're not pulling from the cache
            }

            var vals = filteredValues[k] || v,
                allValues = $.extend({}, _.isPlainObject(vals) ? vals : {}),
                values = $.extend({}, allValues);

            if (input.is(':checkbox') && doFilter) {
                input.each(function(i, checkbox) {
                    ($(checkbox).val() in values ? resultSet : notResultSet).push(checkbox);
                });
            } else if (input.is('select[multiple="multiple"]')) {
                var lis = input.multiselect('widget').find('.ui-multiselect-checkboxes li'),
                    optgroup = null;

                lis.each(function(i, li) {
                    var $li = $(li);

                    if ($li.hasClass('ui-multiselect-optgroup-label')) {
                        if (optgroup && doFilter) {
                            notResultSet.push(optgroup);
                        }

                        optgroup = $li.get(0);
                    } else {
                        var val = $li.find('input').val(),
                            include = val in values;

                        if (doFilter) {
                            (include ? resultSet : notResultSet).push(li);
                        }

                        delete values[val];

                        if (include && optgroup && doFilter) {
                            resultSet.push(optgroup);
                            optgroup = null;
                        }
                    }
                });

                if (optgroup && doFilter) {
                    notResultSet.push(optgroup);
                }

                delete values['null'];

                var numMissing = Object.keys(values).length;

                if (numMissing > 0 && numMissing < 100 && !doFilter) {
                    var idNum = lis.length;

                    $.each(values, function(id, name) {
                        var lastLi = lis.last(),
                            newLi = lastLi.clone().removeClass('hidden'),
                            newLiInput = newLi.find('input'),
                            newLiSpan = newLi.find('span'),
                            newLiLabel = newLi.find('label'),
                            newLiIdParts = newLiInput.attr('id') ? newLiInput.attr('id').split('-') : [];

                        // add an item to the multiselect
                        input.append('<option value="' + id + '">' + name + '</option>');
                        newLiInput.val(id);
                        newLiInput.attr('title', name);
                        newLiSpan.html(name);
                        newLiIdParts[newLiIdParts.length - 1] = idNum++;

                        var newLiId = newLiIdParts.join('-');

                        newLiInput.attr('id', newLiId);
                        newLiLabel.attr('for', newLiId);

                        lastLi.parent().append(newLi);
                    });

                    input.val(Object.keys(allValues)).multiselect('refresh');
                }
            }
        });

        $(checkboxResultSet).button().button('enable');
        $(checkboxNotResultSet).button().button('disable');
        $(notResultSet).addClass('hidden');
        $(resultSet).removeClass('hidden');

        element.data('filtered_values', filteredValues);
        timeEnd('applyUniqueValues');
    }

    function updateFilterDesc(element) {
        var datasetFilters = getDatasetFilters(element),
            chartDiv = getChartDiv(),
            titleOverride = element.data('title_override'),
            filterDesc = chartDiv.find('.filterDesc').empty(),
            dataset = getDatasetOption(element),
            datasetVal = dataset.val(),
            datasetName = $('.dataset[data-dataset="' + datasetVal + '"] span').text(),
            datasetValue = (datasetVal == 'assessments' || datasetVal == 'assessment_students'
                    || datasetVal == 'course_grades' || datasetVal == 'objectives' ? 'Avg. Score' : 'Count'),
            breakout = getBreakoutOption(element),
            breakoutText = (breakout.val() ? breakout.text() : ''),
            modifiers = 'All',
            desc = [],
            chartsPerSeries = getChartsPerSeries(),
            intersections;

        element.data('title_override', null);

        // if we're in a bar chart type scenario
        // figure out what the series names should be (difference) and what the title should be (intersection)
        // update h2's as well
        if (!chartsPerSeries) {
            var allFilters = {},
                breakoutVals = {};

            breakoutText = '';

            var datasetFilters = getDatasetFilters(element);
            var breakout = getBreakoutOption(element);

            if (breakout.val()) {
                breakoutVals[breakout.val()] = breakout.text().replace('By ', '');
            }

            datasetFilters.each(function() {
                var datasetFilter = $(this),
                    filters = getFilters(datasetFilter),
                    index = datasetFilter.index();

                (allFilters[index] = allFilters[index] || []).push(filters);
            });

            var intersections = {},
                diffs = {};
            $.each(allFilters, function(index, filterList) {
                // foreach section, find the set that's the same across all
                var intersection = getIntersection(filterList),
                    diffList = getDiff(filterList, intersection);

                intersections[index] = intersection;
                diffs[index] = diffList;
            });

            breakoutText = (Object.keys(breakoutVals).length ? 'By ' + $.map(breakoutVals, function(v) { return v; }).join('/') : '');
        }

        if (!filterDesc.length) {
            chartDiv.prepend(filterDesc = $('<div class="filterDesc"/>'));
        }

        datasetFilters.each(function() {
            var datasetFilter = $(this),
                index = datasetFilter.index(),
                filters;

            if (intersections !== undefined) {
                filters = intersections[index];
            } else {
                filters = getFilters(datasetFilter);
            }

            var retVal = getFilterDesc(filters, element, datasetFilter, defaultState[index]);

            if ('modifiers' in retVal) modifiers = retVal.modifiers.join(' ');
            desc.concat(retVal.desc);
        });

        filterDesc.html('<h2 class="report-name">'
            + (titleOverride || (modifiers ? modifiers + ' ' : '') + datasetName + (breakoutText ? ' ' + breakoutText : ''))
            + '</h2>'
            + '<h3>'
            + desc.join('; ')
            + '</h3>'
            + '<div>'
            + datasetValue
            + '</div>');
    }

    function getFilterDesc(filters, element, datasetFilter, defaults, full) {
        filters = $.extend(true, {}, filters); // copy

        var breakoutField = getBreakoutField(element),
            simpleVals = ['Yes', 'No', 'All'],
            datasetName = getDatasetOption(element).text(),
            dirtyKey = cleanFind(breakoutField, filters);

        delete filters[dirtyKey]; // we can already see what we're filtered down to, no need to describe it
        if (defaults) {
            delete defaults[breakoutField];
        }

        $.each(filters, function(k, v) {
            if (v.length > 2 && !full) {
                delete filters[k];
            }
        });

        var filterState = getFilterState(filters),
            filterParts = getFilterDisplay(filterState, defaults || {}),
            filterTitle = datasetFilter.data('title'),
            retVal = {desc: []},
            subDesc = [],
            filterDesc = $.grep(filterParts.map(function(o) {
                if ($.inArray(o.value, simpleVals) < 0) return o.value;

                subDesc.push(o.label + '=' + o.value); // put simple vals (e.g. Active: All) in the desc, not in the modifiers
                return '';
            }), function(v) { return v; });

        if (filterParts.length) {
            if (datasetName == filterTitle) {
                retVal.modifiers = filterDesc;
                if (subDesc.length) {
                    retVal.desc.push(filterTitle + ': ' + subDesc.join(', '));
                }
            } else {
                retVal.desc.push(filterTitle + ': ' + filterDesc.concat(subDesc).join(', '));
            }
        }

        return retVal;
    }

    function showEmpty() {
        $('div.state-panel').hide();
        $('.analysis-empty-state').show();
    }

    function hideEmpty() {
        $('#chartWrapper .empty').hide();
    }

    function showChart() {
        $('#chartWrapper .chart').show();
    }

    function hideChart() {
        $('#chartWrapper .chart').hide();
    }

    function getSchoolId() {
        return schoolId;
    }

    function getLoggedInUserId() {
        return loggedInUserId;
    }
}());
