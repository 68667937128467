import * as utils from '../utils';
import AggregateSimpleModel from './simple';

export default function AggregateGradeModel() {
    return $.extend(AggregateSimpleModel.apply(this), {
       gradingScale: null,
       valueKeys: null
    });
}

utils.inheritPrototype(AggregateSimpleModel, AggregateGradeModel, {
    init: function(chartType, datasetDisplay, response, seriesIndex, numSeries, options = []) {
        var self = this;

        self.chartType = chartType;
        self.isBar = isBarChart(chartType);

        self.responseMeta = response.meta;
        self.dataset = self.responseMeta.dataset;
        self.datasetDisplay = datasetDisplay || self.dataset;
        self.data.allSeries = [];
        self.pivot = parseInt(_.get(self.responseMeta, 'charts.0.pivot') || getPivot());

        $.extend(self.options, self.defaults, options);
        self.registerListeners();

        if (!response.success) {
            return;
        }

        self.gradingScale = self.responseMeta.parameters['grading_scale'];
        self.breakout = self.responseMeta.dimensions[0];
        self.seriesBreakout = self.responseMeta.dimensions[1];
        self.valueKeys = self.getValueKeys();

        self.recordsRequestBaseUri = '/api/v1/analysis/' + self.dataset + '/records';
        self.onAfterElementClick = options['onAfterElementClick'] || self.defaultOnAfterElementClick;

        self.loadSeries(response, seriesIndex, numSeries);
    },
    getValueKeys: function() {
        var self = this;

        return {
            label: 'Score',
            key: 'value',
            avgKey: 'avgScore',
            maxKey: 'maxScore',
            countKey: 'count',
            totalCountKey: 'totalCount',
            totalAvgKey: 'totalAvgScore',
            colorKey: 'avgColor',
            recordsKey: 'records'
        };
    },
    augmentPointValue: function(value, v) {
        var self = this;

        if (self.isBar) {
            value[self.valueKeys.countKey] = (v) ? v.rowcount : 0;
            value.score = (v) ? v.score : 0; //cache score to calculate totalScore
            value[self.valueKeys.avgKey] = (v) ? v.score / (v.rowcount || 1) : 0;
            if (self.valueKeys.maxKey) {
                value[self.valueKeys.maxKey] = (v) ? v.maxscore : 0;
            }
        } else {
            value[self.valueKeys.key] = (v) ? v.rowcount : 0;
            value.count = (v) ? v.rowcount : 0;
            value.score = (v) ? v.score : 0; //cache score to calculate totalScore
        }
        return value;
    },
    calculateTotals: function(chart) {
        var self = this;

        if (self.isBar) {
            $.each(self.breakout.values, function(breakoutIndex, breakoutValue) {
                var totalCount = 0;
                var totalScore = 0;
                $.each(self.seriesBreakout.values, function(seriesBreakoutIndex, seriesBreakoutValue) {
                    var series = self.data.allSeries[seriesBreakoutIndex];
                    totalCount += series.values[breakoutIndex][self.valueKeys.countKey];
                    totalScore += series.values[breakoutIndex]['score'];
                });
                $.each(self.seriesBreakout.values, function(seriesBreakoutIndex, seriesBreakoutValue) {
                    var value = self.data.allSeries[seriesBreakoutIndex].values[breakoutIndex];
                    value[self.valueKeys.totalCountKey] = totalCount;
                    value[self.valueKeys.totalAvgKey] = totalScore / (totalCount || 1);
                    value[self.valueKeys.key] = 100 * value[self.valueKeys.countKey] / (totalCount || 1);
                });
            });
        }
    },
    augmentSeries: function(series) {
        var self = this;

        if (self.isBar) {
            if (self.gradingScale) {
                var levels = self.gradingScale.levels;
                var colorScale = Functions.getBackgroundColorScale(levels, true);

                series.color = colorScale(series.seriesIndex);
            }
        } else {
            var totalCount = 0;
            var totalScore = 0;
            $.each(series.values, function(index, value) {
                totalCount += value['count'];
                totalScore += value['score'];
            });

            series[self.valueKeys.avgKey] = totalScore / (totalCount || 1);
        }

        return series;
    },
    addLineSeries: function(allSeries, index) {
        var self = this;

        if (self.isBar && allSeries.length > 0) {
            var lineSeries = $.extend(true, {}, allSeries[0]);

            var colorScale = null;
            if (self.gradingScale) {
                var levels = self.gradingScale.levels;
                colorScale = Functions.getBackgroundColorScale(levels, true);
            }

            $.each(lineSeries.values, function(i, value) {
                if (colorScale) {
                    value[self.valueKeys.colorKey] = colorScale(self.gradingScale.levels.length - 1 - scoreToGradeLevel(value[self.valueKeys.totalAvgKey], self.gradingScale).index);
                } else {
                    value[self.valueKeys.colorKey] = '#1f77b4';
                }
            });

            lineSeries.bar = false;
            lineSeries.key = index;
            lineSeries.label = 'Avg. Score';
            lineSeries.displayName = 'Average Score';
            lineSeries.color = '#1f77b4';

            allSeries.push(lineSeries);
        }
    },
    augmentChart: function(chart, data) {
        var self = this;

        var pctFormat = function(v) { return Math.abs(v).toFixed(0) + '%'; },
            numericFormat = function(v) { return Tss.Number.toCommas(Math.abs(v), 1); },
            y2Format = function(v) {
                return v < 0 || ! self.gradingScale ? '' : ((self.gradingScale.is_pct === 0) ? numericFormat : pctFormat)(v);
            };


        if (self.isBar) {
            chart.legend.reverse(true); // since we build up the data from F to A but want to show the legend A to F
            chart.multibar.dispatch.on('elementClick', self.chartElementClick());
            chart.lines.dispatch.on('elementClick', self.chartElementClick());
            chart.offset(utils.pivotOffset)
                .yDomain(function() {return chart.stacked() ? [-100, 100] : null;})
                .y2(function(d) {return d[self.valueKeys.totalAvgKey];})
                .scatterFillColor(function(d) {return d[self.valueKeys.colorKey];})
                .tooltipContent(function(key, x, y, e, graph) {
                    var data = graph.data();
                    var point = e.point;
                    var series = e.series || e.point;
                    var seriesDisplayName = series.displayName;
                    var seriesLabel = seriesDisplayName || graph.label()(series);
                    var formatter = ((!self.gradingScale || self.gradingScale.is_pct === 0) ? numericFormat : pctFormat);
                    var isAvgBubble = !e.series.bar;
                    var datasetDisplayPlural = _.pluralize(self.datasetDisplay);
                    var pctAndAboveLevel = 0;
                    var pctAndAboveGoal = 0;
                    var barData = _.filter(data, 'bar');
                    var pointIndex = isAvgBubble
                        ? _.findKey(e.series.values, { key: e.point.key }) // can't use pointIndex for avg bubbles
                        : e.pointIndex;

                    for (var i = 0; i < barData.length; i++) {
                        var pct = graph.multibar.y()(barData[i].values[pointIndex], pointIndex);

                        if (i >= self.pivot) {
                            pctAndAboveGoal += pct;
                        }

                        if (i >= e.seriesIndex) {
                            pctAndAboveLevel += pct;
                        }
                    }

                    var count;
                    var avgScoreLabel;
                    var pctAndAboveGoalLabel = `${pctFormat(pctAndAboveGoal)} of ${datasetDisplayPlural} are at or above Goal`;
                    var pctAndAboveLevelLabel = '';
                    if (isAvgBubble) {
                        count = point[self.valueKeys.totalCountKey];
                        avgScoreLabel = `${formatter(point[self.valueKeys.totalAvgKey])} average score in ${x}`;
                    } else {
                        var isFirstOrLastOrGoal = e.seriesIndex == 0
                            || e.seriesIndex == barData.length - 1
                            || e.seriesIndex == self.pivot;

                        count = point[self.valueKeys.countKey];
                        avgScoreLabel = `${y} of ${datasetDisplayPlural} are ${seriesLabel} in ${x}`;
                        pctAndAboveLevelLabel += isFirstOrLastOrGoal
                            ? '' // don't show 100% F and above or %A and above
                            : `${pctFormat(pctAndAboveLevel)} of ${datasetDisplayPlural} are at or above ${seriesLabel}`;
                    }

                    return `
                        <h3>${seriesLabel}</h3>
                        <p>${avgScoreLabel}</p>
                        <p>${pctAndAboveGoalLabel}</p>
                        <p>${pctAndAboveLevelLabel}</p>
                        <p>Click to see ${displayItems(count, self.datasetDisplay)}</p>
                    `;
                });
        } else {
            var gradingScale = getGradingScale() || self.gradingScale,
                colorScale = Functions.getBackgroundColorScale(gradingScale.levels);

            chart.pie.dispatch.on('elementClick', self.chartElementClick());
            chart.color(function(d, i) {return colorScale(i);})
                .tooltipContent(function(key, x, y, e, graph) {
                    var point = e.point,
                        d = e.d,
                        seriesLabel = graph.label()(point),
                        count = y,
                        pct = Math.round(100 * (d.endAngle - d.startAngle) / (2 * Math.PI)),
                        pctAndAbove = Math.round(100 * d.endAngle / (2 * Math.PI)),
                        formatter = ((! self.gradingScale || self.gradingScale.is_pct === 0) ? numericFormat : pctFormat),
                        avgScore = data[0][self.valueKeys.avgKey];

                    return '<h3>' + seriesLabel + '</h3>'
                        + '<p>' + pct + '% in ' + x + '</p>'
                        + (e.index && e.index != data[0].values.length - 1 ? '<p>' + pctAndAbove + '% at or above ' + seriesLabel + '</p>' : '')
                        + '<p>' + formatter(avgScore) + ' average score</p>'
                        + '<p>Click to see ' + displayItems(count, self.datasetDisplay) + '</p>'
                    ;
                });

        }

        if (chart.yAxis) chart.yAxis.tickFormat(pctFormat);
        if (chart.yAxis2 && self.gradingScale) {
            chart.lines.yDomain(function() {
                if (self.gradingScale.is_pct !== 0) {
                    return chart.multibar.yScale().domain();
                }

                var maxMinValue = self.gradingScale.levels[0].min_value;

                return [chart.stacked() ? -maxMinValue : 0, maxMinValue];
            });
            chart.yAxis2.tickFormat(y2Format);
        }

        return chart;
    },
    augmentControls: function(controls) {
        var self = this;

        controls.dataControls = [
            {
                label: 'Avg Score',
                key: 'value',
                avgKey: 'avgScore'
            }
        ];

        // FIXME remove keys that aren't in our map, use keys in the original map that are, add keys that are in our map but not in orig
        controls.sortControls = [
            {label: 'Default', sortCallback: utils.sortCallback('breakoutIndex')},
            {label: 'A-Z', sortCallback: utils.sortCallback('label')},
            {label: 'Avg', sortCallback: utils.sortCallback('totalAvgScore')},
            {label: 'Goal', sortCallback: utils.sortCallback('pivot')},
        ];
    },
    yAxisFormatter: function(v) { return Math.abs(v) < 2 ? v : Tss.Number.toCommas(v); },
    createRawTableAvgScoreCell:function(totalScore, totalRows) {
        //create cell contents for raw table totals row for score type cell
        var self = this,
            avgScore = totalScore / (totalRows || 1);

        totalScore = self.gradingScale.is_pct == 0 ? totalScore : (totalScore.toFixed() + '%');
        return {'html': self.gradingScale.is_pct == 0 ? avgScore.toFixed(4) : (avgScore.toFixed() + '%'),
                'title': totalScore + ' / ' + totalRows,
                'class': 'average pct'}
    }
});