import * as utils from '../utils';
import AggregateSimpleModel from './simple';

export default function AggregateCommunicationModel() {
    return AggregateSimpleModel.apply(this); // super();
}

// inheritance! give sub-class the prototype members of the super class and then override with the child methods
utils.inheritPrototype(AggregateSimpleModel, AggregateCommunicationModel, {
    createRawTableRow: function(dataRow) {
        var self = this;

        return [
                '',
                utils.valueToLink('communication_id', dataRow.communication_id, dataRow.communication_type_name),
                utils.valueToLink('communication_method_id', dataRow.communication_method_id, dataRow.communication_method_name),
                dateStringToDisplay(dataRow.date),
                utils.valueToLink('staff_member_id', dataRow.staff_member_id, dataRow.staff_member_name || ''),
                utils.valueToLink('student_id', dataRow.student_id, dataRow.student_name),
                dataRow.comments || '',
                dataRow.student_id,
                dataRow.school_id_code,
                dataRow.sis_id,
                dataRow.state_id,
                dataRow.grade_level_name,
                dataRow.homeroom || '',
                dataRow.gender || '',
                dataRow.lunch_status || '',
                dataRow.ethnicity || '',
                dataRow.zip || ''
            ];
    },
    createRawTableTotalRow: function(response) {
        var self = this,
            totals = [];

        totals.length = 16;
        if (response.success) {
            var summary = response.meta.summary,
                totalRows = summary.total_rows,
                i = 0;
            totals[i++] = self.createRawTableUniqueCell(summary.communication_type_uniq, totalRows);
            totals[i++] = self.createRawTableUniqueCell(summary.communication_method_uniq, totalRows);
            i++; // skip date
            totals[i++] = self.createRawTableUniqueCell(summary.staff_member_uniq, totalRows);
            totals[i++] = self.createRawTableUniqueCell(summary.student_uniq, totalRows);
            totals[i++] = self.createRawTableUniqueCell(summary.comments_uniq, totalRows);
            totals[i++] = self.createRawTableUniqueCell(summary.student_uniq, totalRows);
            totals[i++] = self.createRawTableUniqueCell(summary.school_id_code_uniq, totalRows);
            totals[i++] = self.createRawTableUniqueCell(summary.sis_uniq, totalRows);
            totals[i++] = self.createRawTableUniqueCell(summary.state_id_uniq, totalRows);
            totals[i++] = self.createRawTableUniqueCell(summary.grade_level_uniq, totalRows);
            totals[i++] = self.createRawTableUniqueCell(summary.homeroom_uniq, totalRows);
            totals[i++] = self.createRawTableUniqueCell(summary.gender_uniq, totalRows);
            totals[i++] = self.createRawTableUniqueCell(summary.lunch_status_uniq, totalRows);
            totals[i++] = self.createRawTableUniqueCell(summary.ethnicity_uniq, totalRows);
            totals[i++] = self.createRawTableUniqueCell(summary.zip_uniq, totalRows);
        }

        return totals;
    }
});