import * as utils from '../utils';
import AggregateSimpleModel from './simple';

export default function AggregateAbsenceModel() {
    return AggregateSimpleModel.apply(this); // super();
}

// inheritance! give sub-class the prototype members of the super class and then override with the child methods
utils.inheritPrototype(AggregateSimpleModel, AggregateAbsenceModel, {
    augmentPointValue: function(value, v) {
        value.value = v ? v.pct : 0;
        value.count = v ? v.rowcount : 0;
        value.denGrouped = v ? v.den_all_dims : 0;
        value.denStacked = v ? v.den_one_dim : 0;
        return value;
    },
    yAxisFormatter: function(v) { return (Math.round(v * 100) / 100) + '%'; },
    augmentChart: function(chart, data) {
        var self = this;

        chart = AggregateAbsenceModel.prototype.parent.augmentChart.call(self, chart, data);

        if (self.isBar) {
            // namespace the event otherwise this doesn't get called at all
            chart.controls.dispatch.on('legendClick.absences', function(d, i) {
                // figure out stacked manually and pass it in because we're getting
                // called before the default event handler updates the chart
                // setting to stacked/grouped.
                // if you clicked stacked, was it disabled? if so => stacked.
                // if you clicked grouped, was it enabled? if so => stacked.
                var stacked = d.key == 'stacked' ? d.disabled : !d.disabled;

                self.calculateTotals(chart, stacked);
            });
            chart.maxY(100) // don't show a bar for 110% just because the total is 100.1 due to rounding
                .yTicks(19) // show 95% not just 90% and 100%
                .tooltipContent(function(key, x, y, e, graph) {
                    var point = e.point;
                    var series = e.series || e.point;
                    var isNoneBucket = point.label == '[None] Present'
                        || series.label == '[None] Present';
                    var seriesLabel = graph.label()(series);
                    var label = point.label && point.label != seriesLabel
                        ? point.label + ': ' + seriesLabel
                        : seriesLabel;
                    var count = utils.formatNumber(
                        e.series.bar ? point.count : point['totalCount']
                    );
                    var drilldownText =
                        '<p>'
                        + (isNoneBucket ? '' : 'Click to see ')
                        + displayItems(count, self.datasetDisplay)
                        + '</p>';

                return '<h3>' + label + '</h3>'
                    + drilldownText
                    + self.augmentTooltip(e, graph)
                ;
            });
        } else {
            chart.tooltipContent(function(key, x, y, e, graph) {
                var point = e.point;
                var d = e.d;
                var seriesLabel = graph.label()(point);
                var count = point.count;
                var pct = Math.round(100 * (d.endAngle - d.startAngle) / (2 * Math.PI));
                var isNoneBucket = point.label == '[None] Present'
                    || seriesLabel == '[None] Present';
                var drilldownText =
                    '<p>'
                    + (isNoneBucket ? '' : 'Click to see ')
                    + displayItems(count, self.datasetDisplay)
                    + '</p>';

                return '<h3>' + seriesLabel + '</h3>'
                    + '<p>' + pct + '% in ' + x + '</p>'
                    + drilldownText
                    + self.augmentTooltip(e, graph)
                ;
            });
        }

        return chart;
    },
    augmentControls: function(controls) {
        controls.dataControls = [
            {label: 'Count', key: 'value'},
        ];

        controls.sortControls = [
            {label: 'Default', sortCallback: utils.sortCallback('breakoutIndex')},
            {label: 'A-Z', sortCallback: utils.sortCallback('label')},
            {label: 'Total', sortCallback: utils.sortCallback('totalValue', this)},
        ];
    },
    augmentTooltip: function(e, graph) {
        var point = e.point,
            series = e.series || e.point,
            seriesLabel = graph.label()(series),
            retval = '<p>' + Math.round(point.value) + '% ' + seriesLabel + '</p>'
                + (!this.isBar || graph.stacked()
                    ? '<p>' + Math.round(point.totalValue) + '% Total</p>'
                    : '');

        return retval;
    },
    calculateTotals: function(chart, stacked) {
        var self = this;

        if (self.isBar) {
            chart = chart || $('.chart').first().data('chart');
            stacked = stacked !== undefined
                ? stacked
                : (chart
                    ? chart.stacked()
                    : true);

            var denKey = stacked ? 'denStacked' : 'denGrouped';

            $.each(self.breakout.values, function(breakoutIndex, breakoutValue) {
                var totalCount = 0;
                var totalDen = 0;

                $.each(self.seriesBreakout.values, function(seriesBreakoutIndex, seriesBreakoutValue) {
                    var series = self.data.allSeries[seriesBreakoutIndex];

                    if (!series.disabled) {
                        totalCount += series.values[breakoutIndex].count;
                        totalDen = Math.max(totalDen, series.values[breakoutIndex][denKey]);
                        series.values[breakoutIndex].value =
                            100
                            * series.values[breakoutIndex].count
                            / (series.values[breakoutIndex][denKey] || 1);
                    }
                });

                $.each(self.seriesBreakout.values, function(seriesBreakoutIndex, seriesBreakoutValue) {
                    var series = self.data.allSeries[seriesBreakoutIndex];

                    series.values[breakoutIndex].totalCount = totalCount;
                    series.values[breakoutIndex].totalValue = 100 * totalCount / (totalDen || 1);
                });
            });
        }
    },
    createRawTableRow: function(dataRow) {
        var self = this;

        return [
                '',
                utils.valueToLink('absence_id', dataRow.absence_id, dataRow.absence_type_name),
                dateStringToDisplay(dataRow.date),
                utils.valueToLink('staff_member_id', dataRow.staff_member_id, dataRow.staff_member_name || ''),
                utils.valueToLink('student_id', dataRow.student_id, dataRow.student_name),
                dataRow.student_id,
                dataRow.school_id_code,
                dataRow.sis_id,
                dataRow.state_id,
                dataRow.grade_level_name,
                dataRow.homeroom || '',
                dataRow.gender || '',
                dataRow.lunch_status || '',
                dataRow.ethnicity || '',
                dataRow.zip || ''
            ];
    },
    createRawTableTotalRow: function(response) {
        var self = this,
            totals = [];

        totals.length = 14;
        if (response.success) {
            var summary = response.meta.summary,
                totalRows = summary.total_rows,
                i = 0;
            totals[i++] = self.createRawTableUniqueCell(summary.absence_type_uniq, totalRows);
            i++; // skip date
            totals[i++] = self.createRawTableUniqueCell(summary.staff_member_uniq, totalRows);
            totals[i++] = self.createRawTableUniqueCell(summary.student_uniq, totalRows);
            totals[i++] = self.createRawTableUniqueCell(summary.student_uniq, totalRows);
            totals[i++] = self.createRawTableUniqueCell(summary.school_id_code_uniq, totalRows);
            totals[i++] = self.createRawTableUniqueCell(summary.sis_uniq, totalRows);
            totals[i++] = self.createRawTableUniqueCell(summary.state_uniq, totalRows);
            totals[i++] = self.createRawTableUniqueCell(summary.grade_level_uniq, totalRows);
            totals[i++] = self.createRawTableUniqueCell(summary.homeroom_uniq, totalRows);
            totals[i++] = self.createRawTableUniqueCell(summary.gender_uniq, totalRows);
            totals[i++] = self.createRawTableUniqueCell(summary.lunch_status_uniq, totalRows);
            totals[i++] = self.createRawTableUniqueCell(summary.ethnicity_uniq, totalRows);
            totals[i++] = self.createRawTableUniqueCell(summary.zip_uniq, totalRows);
        }

        return totals;
    }
});