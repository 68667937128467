import * as utils from '../utils';

export default init;

function init() {
    loadMyReports();

    $('.search input').hideseek();

    $('.report-viewer')
        .on('load-report', markReportLinkActive)
        .on('click', '.report-list li a', reportLinkClicked);
}

function loadMyReports(event) {
    // load both shared and saved reports,
    // combine,
    // render,
    // fetch report id from browser address
    // mark active link
    var savedReportPromise = _getMyReportsOfType('saved-reports');
    var sharedReportPromise = _getMyReportsOfType('shared-reports');

    return $.when(sharedReportPromise, savedReportPromise)
        .then(mergeSharedAndSavedReports)
        .then(dedupeReports)
        .then(renderSharedReports)
        .then(utils.getReportIdFromUrl)
        .then(function (reportId) {
            return markReportLinkActive(null, reportId);
        });
}

function mergeSharedAndSavedReports(sharedReports, savedReports) {
    return sharedReports.concat(savedReports);
}

function dedupeReports(reports) {
    return _.uniqBy(reports, 'analysis_report_id');
}

function renderSharedReports(reports) {
    var source   = $("#report-list-template").html();
    var template = Handlebars.compile(source);
    $('.report-list').html(template({'reports': reports}));

    // render popovers dynamically
    $('.report-viewer-sidebar [rel="make-tss-popover"]').tssPopover({
        onAfterClick: loadPopoverContents
    });

    // function exists within renderSharedReports function so its private and
    // we dont have to worry about passing around the array of shared reports
    function loadPopoverContents(popover) {
        var popoverContents = popover.find('.popover-contents');

        // quit eary since we dont want to re-render popover content thats already rendered
        if (popoverContents.find('.shared-report-details').length) {
            return;
        }

        // get report id based on report link href, then find report
        var analysisReportId = utils.getReportIdFromUrl(popover.next('a').attr('href'));
        var sharedReport     = _.find(reports, {'analysis_report_id': analysisReportId});

        // resolve links on shared report, then append in popover DOM
        utils.resolveLinks(sharedReport, ['shared_to', 'created_by'])
            .then(cleanupResolvedObjects)
            .then(_.partialRight(compilePopoverTemplateForReportAndAppend, popoverContents));
    }
}

// this is needed becasue we have a super generic resolve links method
// that does not know what part of the response to attach to the object,
// so it just attaches all results. This function cleans that up.
// ie. report.created_by.users.3.{user_obj} => report.created_by.{user_obj}
function cleanupResolvedObjects(report) {
    report.created_by = report.created_by.user;
    report.shared_to = report.shared_to.users;
    return report;
}

function compilePopoverTemplateForReportAndAppend(report, popoverContents) {
    var popoverHtml = utils.compileTemplateWithId('report-list-shared-to-popover-template', { report });
    popoverContents.html(popoverHtml);
}

function reportLinkClicked(event) {
    event.preventDefault();
    var url = $(this).attr('href');
    var reportId = utils.getReportIdFromUrl(url);
    fetch('/totango/loadSavedReportMyReports/Analysis')

    // fetch data and generate visuals
    $('.report-viewer').trigger('load-report', reportId);
}

function markReportLinkActive(event, reportId) {
    // remove old active
    // and find report link with matching report id
    $('.report-list li')
        .removeClass('active')
        .find('a[href$="report_id=' + reportId + '"]')
            .parent()
            .addClass('active');
}

function _getMyReportsOfType(reportType) {
    var resultsKey = reportType.replace('-', '_');

    return $.get('/api/v1/me/' + reportType)
        .then(function (response) {
            return response.results[resultsKey];
        });
}