import * as utils from '../utils';
import * as rawData from '../rawdata';

export default function AggregateSimpleModel() {
    return $.extend(this, {
        data: {},
        breakout: null,
        seriesBreakout: null,
        dataset: null,
        datasetDisplay: null,
        responseMeta: null,
        chartType: null,
        isBar: null,
        defaults: {},
        options: {},
        rawDataSelDimValues : []
    });
}

AggregateSimpleModel.prototype = {
    constructor: AggregateSimpleModel,
    init: function(chartType, datasetDisplay, response, seriesIndex, numSeries, options = []) {
        var self = this;

        self.chartType = chartType;
        self.isBar = isBarChart(chartType);

        self.responseMeta = response.meta;
        self.dataset = self.responseMeta.dataset;
        self.datasetDisplay = datasetDisplay || self.datasetDisplay || self.dataset;
        self.data.allSeries = [];

        $.extend(self.options, self.defaults, options);
        self.registerListeners();

        if (!response.success) {
            return;
        }

        self.breakout = self.responseMeta.dimensions[0];
        self.seriesBreakout = self.responseMeta.dimensions[1];

        self.recordsRequestBaseUri = '/api/v1/analysis/' + self.dataset + '/records';
        self.onAfterElementClick = options['onAfterElementClick'] || self.defaultOnAfterElementClick;

        self.loadSeries(response, seriesIndex, numSeries);
    },
    registerListeners: function() {
        var self = this;

        $('body').on('clearRawTable', function() {
            self.rawDataSelDimValues = []; // empty the collection of filters when they click Clear Results
        });
    },
    loadSeries: function(response, seriesIndex, numSeries) {
        var self = this;

        if (self.isBar) {
            //load for bar type graphs
            var seriesBreakoutValues = self.seriesBreakout.values;
            if (self.seriesBreakout.id === 'grading_scale_level_id') {
                //reverse if series breakout is grading scale so graphs layout from higher grade to lower grade
                seriesBreakoutValues = seriesBreakoutValues.slice(0).reverse();
            }
            $.each(seriesBreakoutValues, function(seriesBreakoutIndex, seriesBreakoutValue) {
                var seriesBreakoutId = seriesBreakoutValue.id;
                var series = {
                    bar: self.isBar,
                    key: seriesBreakoutId,
                    label: seriesBreakoutValue.name,
                    displayName: seriesBreakoutValue.display_name || seriesBreakoutValue.name,
                    title: seriesBreakoutValue.title || '',
                    seriesIndex: seriesBreakoutIndex,
                    values: []
                };
                $.each(self.breakout.values, function(breakoutIndex, breakoutValue) {
                    var breakoutId = breakoutValue.id;
                    var value = {
                        key: seriesIndex + '_' + breakoutId,
                        label: ((numSeries > 1) ? 'Series ' + (seriesIndex + 1) + ' - ' : '') + breakoutValue.name,
                        breakoutVal: breakoutId,
                        breakoutDisplay: breakoutValue.name,
                        seriesIndex: seriesIndex,
                        breakoutIndex: breakoutIndex,
                        dimensionValues: [breakoutId, seriesBreakoutId]
                    };
                    if (response.results.data[breakoutId] && response.results.data[breakoutId][seriesBreakoutId]) {
                        value = self.augmentPointValue(value, response.results.data[breakoutId][seriesBreakoutId]);
                    } else {
                        value = self.augmentPointValue(value, null);
                    }
                    series.values[series.values.length] = value;
                });

                self.data.allSeries[self.data.allSeries.length] = self.augmentSeries(series);
            });
        } else {
            //load for pie chart type graph
            $.each(self.breakout.values, function(breakoutIndex, breakoutValue) {
                var breakoutId = breakoutValue.id;
                var series = {
                    key: seriesIndex + '_' + breakoutId,
                    label: breakoutValue.name,
                    breakoutVal: breakoutId,
                    breakoutDisplay: ((numSeries > 1) ? 'Series ' + (seriesIndex + 1) + ' - ' : '') + breakoutValue.name,
                    dataset: self.datasetDisplay,
                    values: []
                };
                $.each(self.seriesBreakout.values, function(seriesBreakoutIndex, seriesBreakoutValue) {
                    var seriesBreakoutId = seriesBreakoutValue.id;
                    var value = {
                        key: seriesBreakoutId,
                        label: seriesBreakoutValue.name,
                        displayName: seriesBreakoutValue.display_name || seriesBreakoutValue.name,
                        title: seriesBreakoutValue.title || '',
                        dimensionValues: [breakoutId, seriesBreakoutId]
                    };
                    if (response.results.data[breakoutId] && response.results.data[breakoutId][seriesBreakoutId]) {
                        value = self.augmentPointValue(value, response.results.data[breakoutId][seriesBreakoutId]);
                    } else {
                        value = self.augmentPointValue(value, null);
                    }
                    series.values[series.values.length] = value;
                });

                self.data.allSeries[self.data.allSeries.length] = self.augmentSeries(series);
            });
        }

        self.calculateTotals(null); // no chart yet
        self.addLineSeries(self.data.allSeries, seriesIndex);
    },
    augmentPointValue: function(value, v) {
        value.value = (v) ? v.rowcount : 0;
        value.count = value.value;
        return value;
    },
    calculateTotals: function(chart) {
        var self = this;
        if (self.isBar) {
            $.each(self.breakout.values, function(breakoutIndex, breakoutValue) {
                var totalCount = 0;
                $.each(self.seriesBreakout.values, function(seriesBreakoutIndex, seriesBreakoutValue) {
                    var series = self.data.allSeries[seriesBreakoutIndex];
                    if (!series.disabled) {
                        totalCount += series.values[breakoutIndex].count;
                    }
                });
                $.each(self.seriesBreakout.values, function(seriesBreakoutIndex, seriesBreakoutValue) {
                    var series = self.data.allSeries[seriesBreakoutIndex];
                    series.values[breakoutIndex].totalCount = totalCount;
                });
            });
        }
    },
    augmentSeries: function(series) {return series;},
    addLineSeries: function(allSeries, index) {},
    augmentChart: function(chart, data) {
        var self = this;

        if (self.isBar) {
            chart.multibar.dispatch.on('elementClick', self.chartElementClick());
            chart.lines.dispatch.on('elementClick', self.chartElementClick());
            chart.tooltipContent(function(key, x, y, e, graph) {
                var point = e.point,
                    series = e.series || e.point,
                    seriesLabel = graph.label()(series),
                    label = point.label && point.label != seriesLabel
                        ? point.label + ': ' + seriesLabel
                        : seriesLabel;

                var count = e.series.bar ? point.count : point['totalCount'];
                count = utils.formatNumber(count);

                return '<h3>' + label + '</h3>'
                    + '<p>Click to see ' + displayItems(count, self.datasetDisplay) + '</p>'
                    + self.augmentTooltip(e, graph)
                ;
            });
        } else {
            chart.pie.dispatch.on('elementClick', self.chartElementClick());
            chart.tooltipContent(function(key, x, y, e, graph) {
                var point = e.point,
                    d = e.d,
                    seriesLabel = graph.label()(point),
                    count = point.count,
                    pct = Math.round(100 * (d.endAngle - d.startAngle) / (2 * Math.PI));

                return '<h3>' + seriesLabel + '</h3>'
                    + '<p>' + pct + '% in ' + x + '</p>'
                    + '<p>Click to see ' + displayItems(count, self.datasetDisplay) + '</p>'
                    + self.augmentTooltip(e, graph)
                ;
            });
        }

        if (chart.yAxis) chart.yAxis.tickFormat(self.yAxisFormatter);

        return chart;
    },
    augmentTooltip: function(e, graph) {
        return '';
    },
    augmentControls: function(controls) {
        controls.dataControls = [
            {label: 'Count', key: 'value'},
        ];

        controls.sortControls = [
            {label: 'Default', sortCallback: utils.sortCallback('breakoutIndex')},
            {label: 'A-Z', sortCallback: utils.sortCallback('label')},
            {label: 'Total', sortCallback: utils.sortCallback('totalCount', this)},
        ];
    },
    yAxisFormatter: function(v) { return Math.abs(v) < 2 ? v : Tss.Number.toCommas(v); },
    defaultOnAfterElementClick: function (model) {
        var triggerViaChartClick = true;
        $('[data-tab="records"]').trigger('click', triggerViaChartClick);
    },
    chartElementClick: function() {
        //load raw table data on chart point click
        var self = this;

        return function(e) {
            var point = e.point;
            var pointDimValues = point.dimensionValues;

            if (e.series && !e.series.bar) {
                pointDimValues[1] = ''; // get rid of bar section if we clicked the avg dot
            }

            // add to dimension values
            self.rawDataSelDimValues[self.rawDataSelDimValues.length] = pointDimValues;

            // call on after click event with model (self)
            self.onAfterElementClick(self);
        };
    },
    createRawTableHeader: function(headers) {
        var headerRow = "<tr class='headerRow'>";
        $.each(headers, function(i, header) {
            headerRow += "<th class='" + header.sortkey + "'>" + header.name + "</th>";
        });
        headerRow += "</tr>";

        //totals row
        headerRow += "<tr class='totals-row'><td data-nocount='1'>Totals</td>";
        $.each(headers, function(i, header) {
            //skip first column
            if (i > 0) {
                headerRow += "<td></td>";
            }
        });
        headerRow += "</tr>";
        return headerRow;
    },
    createRawTableRows: function(table, response) {
        var self = this,
            totalRows = 0,
            rows = [];

        //rows
        if (response.success) {
            totalRows = parseInt(response.meta.summary.total_rows);

            $.each(response.results.data, function(i, dataRow) {
                var row = self.createRawTableRow(dataRow);
                $.each(row, function(j, cell) {
                    if (('active' in dataRow) && !dataRow.active) {
                        //set deactivated class on inactive records
                        row[j] = "<span class='deactivated'>" + cell + "</span>";
                    }

                    // left align non-numeric data
                    row[j] = '<td'
                        + (isNumber(cell) ? '' : ' class="text"')
                        + '>'
                        + (cell || '')
                        + '</td>';
                });

                rows.push(row);

            });
        }

        //totals row
        self.setRawTableTotals(table, self.createRawTableTotalRow(response));

        return [totalRows, rows];
    },
    createRawTableRow: function(dataRow) {
        //return array for a single row for raw data table, one element for each table column
        //array element will be an object with two keys sortkey and name,
        //e.g. [sortkey: '...', name: '...'}, ...]
        return [];
    },
    createRawTableTotalRow: function(response) {
        //return array for totals row, one element for each table column except for 1st column (label column)
        //array element can be null/undefined or an object with keys html, title and class,
        //e.g [{html: '..', title: '...', class: '...'}, ...]
        return [];
    },
    setRawTableTotals: function(table, totals) {
        var totalsRow = $('tr.totals-row:first', table);
        $.each(totals, function(i, total) {
            if (!total) {
                total = {html: '', title: ''}; //empty the cell
            }
            var totalsCell = totalsRow.find('td:eq(' + (i+1) + ')');
            totalsCell.html(total.html);
            if (total.title) {
                totalsCell.attr('title', total.title);
            }
            if (total['class']) {
                totalsCell.attr('class', total['class']);
            }
        });
    },
    resetRawTableTotals: function(table) {
        //clear html content and title for each column cell in totals row except the first
        table.find('thead > tr.totals-row:first > td').not('td:first').html('').attr('title', '');
    },
    createRawTableUniqueCell:function(uniq, total) {
        //create cell contents for unique count for raw table totals row
        return {'html': uniq + ' / ' + total,
                'title': uniq + ' unique out of ' + total + ' total',
                'class': 'total uniq'};
    },
    createRawTableTotalCell:function(total) {
        return {'html': total, 'class': 'total'};
    },
    getRecordsRequestBaseUri: function () {
        return this.recordsRequestBaseUri;
    },
    setRecordsRequestBaseUri: function (baseUri) {
        this.recordsRequestBaseUri = baseUri;
    }
}
