import * as pivot from '../pivottable';
import * as utils from '../utils';

export function init() {
    insertEmptyState();

    $('.data-picker-btn, .create-report-btn')
        .on('click', toggleDataPicker);

    $('[rel="make-tss-popover"]')
        .on('click', hideDataPicker);

    $('.data-picker')
        .on('click', '.dataset', selectDataset)
        .on('click', '.change-dataset', changeDataset)
        .on('click', '.data-picker-cancel', cancelDataPicker)
        .on('click', '.data-picker-submit', submitDataPicker);

    $('input[name="submitted_dataset"]')
        .on('change', markSubmittedDatasetTile);

    orderDatasetTiles();
}

function orderDatasetTiles() {
    var datasets = $('.data-picker .dataset');
    var sortedDatasets = _.sortBy(datasets, dataset => $(dataset).find('.dataset-name').text());

    _.each(sortedDatasets, (dataset, index) => {
        var css = { 'order': index };

        $(dataset).parent().css(css);
    });
}

function selectDataset(event) {
    var dataset = $(this).data('dataset');
    var filterSets = _.split($(this).data('filter-sets'), ',').concat('display'); // always make sure display is in the set
    var datasetTitle = $(this).find('.dataset-name').html();

    // update selected dataset & show change dataset btn
    $('.selected-dataset').html(datasetTitle);
    $('.change-dataset').show();

    // insert value into hidden input
    $('input[name="dataset"]').val(dataset).trigger('change');

    // show just the tabs and tab panels we need for this dataset
    $('ul.tabs li').hide();
    $('ul.tabs li[data-tab=' + filterSets.join('], ul.tabs li[data-tab=') + ']').css({'display': 'inline-flex'});

    // make sure display tab is showing
    $('ul.tabs li[data-tab="display"]').trigger('click');

    // get the empty panels and fill them with html from server
    var emptyPanels = $('.tab-content #' + filterSets.join(', #')).filter(':empty');
    var promises = _.map(emptyPanels, fetchFilterHtml);

    // when all filter html panes are loaded prefill form values
    if (promises.length) {
        $.when.apply($, promises)
            .then(fillInInitialFormValues);
    }

    // load breakout options
    var breakoutPromise = $.get('/api/v1/analysis/breakout-options', { dataset: dataset });
    // ANLYS-152: don't allow the user to click Load Data until the breakout
    // options have returned. Otherwise you get weird errors.
    $('.data-picker-submit').attr('disabled', 'disabled');
    $.when()
        .then(clearBreakoutDropdowns)
        .then(() => breakoutPromise)
        .then(_.property('results.options'))
        .then(fillBreakoutDropdowns)
        .then(fillInPivotBreakdowns)
        .then(triggerPivotsAreReady);

    // hide/show controls for dataset (breakout, grading scale, pivot)
    hideOrShowChartControlsForDataset(dataset);

    // show filter panel
    swapPickerPanels();

    // build an array of all promises needed to generate dynamic data in picker
    // when all done lets auto-save if we dont have filter details
    // finally set state on data-picker to null.
    var filterPanePromises = promises.concat(breakoutPromise);
    var hasRawAndGradebook = dataset == 'assessments' || dataset == 'assessment_students'; // don't show raw vs gradebook for student standard scores dataset
    $.when.apply($, filterPanePromises)
        // the line below needs to be moved to the end of the
        // filter panel promise resolve chain if we remove auto-save
        .then(() => $('.data-picker').data('state', null))
        .then(() => $('#assessments .avgScore')[hasRawAndGradebook ? 'show' : 'hide']())
        .then(() => $('.data-picker-submit').removeAttr('disabled'));
}

function changeDataset() {
    resetDataset();
    equalHeightDatasetTiles();
}

function resetDataset(event) {
    $('.selected-dataset').html('');
    $('input[name="dataset"]').val(null).trigger('change');
    swapPickerPanels();
}

function submitDataPicker(event) {
    var dataset = $('input[name="dataset"]').val();
    $('input[name="submitted_dataset"]').val(dataset).trigger('change');

    AnalysisView.submitAnalysisData();
    toggleDataPicker();
}

function cancelDataPicker(event) {
    // revert to the filter inputs to match the loaded report on cancel
    AnalysisView.popState();
    toggleDataPicker();
}

function markSubmittedDatasetTile() {
    var submittedDataset = $('input[name="dataset"]').val();

    $('.dataset').removeClass('submitted');
    $('.dataset[data-dataset="' + submittedDataset + '"]').addClass('submitted')
}

function toggleDataPicker(event) {
    $(this).toggleClass('open');

    $('.data-picker')
        .toggle()
        .promise()
        .then(equalHeightDatasetTiles);

    // so that the Create New Report button in the Saved Reports dropdown works.
    // otherwise we hit the event listener that causes the picker to be hidden.
    stopPropagation(event);
}

function equalHeightDatasetTiles() {
    var maxHeight = 0;
    var datasets= $('.data-picker .dataset:visible');

    // only equal height if they are visible
    if (!datasets.length) {
        return;
    }

    datasets.each(function() {
        var height = $(this).height();
        if (height > maxHeight) {
            maxHeight = height;
        }
    });

    datasets.height(maxHeight);
}

function hideDataPicker(event) {
    $('.data-picker-btn').removeClass('open');
    $('.data-picker').hide();
}

function swapPickerPanels() {
    $('.data-picker div[class*="-step"]').toggle();
}

function fetchFilterHtml(panel) {
    var $panel = $(panel);
    var filterSet = $panel.attr('id');

    AnalysisView.incrementCounter();

    $panel.html('<div class="loading text-center"><div class="spencil-loader"></div></div>');

    return $.get('/api/v1/analysis/filter-html', { dataset: filterSet, counter: AnalysisView.getCounter() })
        .then(_.partial(insertFilterHtml, $panel))
        .then(makeFilterInputsFancy);
}

// when each panel is done
// - set panel html
// - setup all bindings and fancy input overrides
function insertFilterHtml(panel, html) {
    return panel.html(html).promise().then(_.constant(panel));
}

function makeFilterInputsFancy(panel) {
    return panel.each(setupUI)
        .promise()
        .then(fixDateInputs)
        .then(_.constant(panel));
}

function fillInInitialFormValues() {
    // fill in the DEFAULT values that are passed in via PHP from the view
    $.each(initialOptions, fillInForm);

    // get state value on data-picker (only exists when loading from state)
    var state = $('.data-picker').data('state');
    var filters = _.get(state, 'filters[0]');

    // if we just updated from state
    // select "All" for active inputs
    // this will be overwritten if a user specifically set an active value in a saved report
    if (state) {
        $('[id$="_active_"]').trigger('click')
    }

    // fill in filters
    AnalysisView.fillInAllFilters(filters);
}

function clearBreakoutDropdowns() {
    return fillBreakoutDropdowns({'': 'None'});
}

function fillBreakoutDropdowns(options) {
    var breakout = $('[name="breakout"]');
    var seriesBreakout = $('[name="series_bucket"]');

    var optionHtml = Object.keys(options).reduce((html, key) => {
        return html + `<option value="${key}">${options[key]}</option>`;
    }, '');

    breakout.html(optionHtml);
    seriesBreakout.html(optionHtml)

    // these can be a little slow and don't need to happen synchronously
    // so throw them in a setTimeout so when you click a dataset there isn't
    // a pause waiting for this to happen
    setTimeout(function() {
        breakout.each(hyjackSelect);
        seriesBreakout.each(hyjackSelect);
    }, 10);

    return options;
}

function fillInPivotBreakdowns() {
    var dataPicker = $('.data-picker');
    var breakout = dataPicker.find('[name="breakout"]');
    var pivotDiv = pivot.getPivotDiv();
    var pivotSelects =  pivotDiv.find('[name*="pivot_table_left"], [name*="pivot_table_top"]');

    pivotSelects
        .empty()
        .append(breakout.find('option').clone())
        .each(hyjackSelect);
}

function triggerPivotsAreReady() {
    $('.data-picker').trigger('breakout-and-pivot-selects-ready');
}

function hideOrShowChartControlsForDataset(dataset) {
    var assessmentsDatasets = ['assessments', 'assessment_students', 'course_grades', 'objectives'];
    var pivotDiv = pivot.getPivotDiv();
    var demeritValuesControl = pivotDiv.find('.demeritValues');
    var avgScoreControl = pivotDiv.find('.avgScore');
    var funcAvgControl = pivotDiv.find('[name="func"][value="avg"]');
    var funcCountControl = pivotDiv.find('[name="func"][value="count"]');
    var masteryResultsControl = pivotDiv.find('.masteryResults');

    // show avg/curved if assessment dataset
    if (_.includes(assessmentsDatasets, dataset)) {
        $('[name="series_bucket"]').parent().hide();
        $('[name="grading_scale"],[name="pivot"]').parent().show();
        funcAvgControl.prop('checked', true).trigger('change');
        avgScoreControl[dataset != 'objectives' ? 'show' : 'hide']();
    } else {
        $('[name="series_bucket"]').parent().show();
        $('[name="grading_scale"],[name="pivot"]').parent().hide();
        funcCountControl.prop('checked', true).trigger('change');
        avgScoreControl.hide();
    }

    // show demerit controls if dataset == demerits
    if (dataset == 'demerits') {
        demeritValuesControl.show();
    } else {
        demeritValuesControl.hide();
    }

    // show mastery results if objectives dataset
    // this doesn't work right now so don't show it
    // if (dataset == 'objectives') {
    //     masteryResultsControl.show();
    // } else {
    masteryResultsControl.hide();
    // }
}

function insertEmptyState() {
    var savedReportsAccordion = $(".saved-reports-accordion");

    if (!savedReportsAccordion.children().length) {
        var emptyState = Handlebars.renderTemplate('empty-state', { title: 'No Saved Reports Yet' }),
            createNewReportButton = $('.create-report-btn').clone();

        savedReportsAccordion.after($(emptyState).append(createNewReportButton));
    }
}