import * as utils from '../utils';
import AggregateSimpleModel from './simple';

export default function AggregateStudentModel() {
    return AggregateSimpleModel.apply(this); // super();
}

// inheritance! give sub-class the prototype members of the super class and then override with the child methods
utils.inheritPrototype(AggregateSimpleModel, AggregateStudentModel, {
    createRawTableRow: function(dataRow) {
        var self = this;

        return [
                '',
                utils.valueToLink('student_id', dataRow.student_id, dataRow.student_name),
                dataRow.student_id,
                dataRow.school_id_code,
                dataRow.sis_id,
                dataRow.state_id,
                dataRow.grade_level_name,
                dataRow.homeroom || '',
                dataRow.gender || '',
                dataRow.lunch_status || '',
                dataRow.ethnicity || '',
                dataRow.zip || ''
            ];
    },
    createRawTableTotalRow: function(response) {
        var self = this,
            totals = [];

        totals.length = 9;
        if (response.success) {
            var summary = response.meta.summary,
                totalRows = summary.total_rows;
            totals[0] = self.createRawTableUniqueCell(summary.student_uniq, totalRows);
            totals[1] = self.createRawTableUniqueCell(summary.student_uniq, totalRows);
            totals[2] = self.createRawTableUniqueCell(summary.school_id_code_uniq, totalRows);
            totals[3] = self.createRawTableUniqueCell(summary.sis_uniq, totalRows);
            totals[4] = self.createRawTableUniqueCell(summary.state_id_uniq, totalRows);
            totals[5] = self.createRawTableUniqueCell(summary.grade_level_uniq, totalRows);
            totals[6] = self.createRawTableUniqueCell(summary.homeroom_uniq, totalRows);
            totals[7] = self.createRawTableUniqueCell(summary.gender_uniq, totalRows);
            totals[8] = self.createRawTableUniqueCell(summary.lunch_status_uniq, totalRows);
            totals[9] = self.createRawTableUniqueCell(summary.ethnicity_uniq, totalRows);
            totals[10] = self.createRawTableUniqueCell(summary.zip_uniq, totalRows);
        }

        return totals;
    }
});